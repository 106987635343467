import { Fragment, useContext, useState } from "react"
import classes from "./ClientDropdown.module.css";
import Dropdown from "../Dropdown/Dropdown";
import ClientContext from "../../Context/client-context";
import { Country, Environment, StatementType } from "../../API";

const ClientDropdown = (props: any) => {

    const {
        activeAgency, 
        setActiveAgency, 
        activeCountry, 
        setActiveCountry, 
        activeAccessType, 
        setActiveAccessType, 
        activeEnvironment, 
        setActiveEnvironment
    } = useContext(ClientContext);

    const agencies: string[] = ["OMD", "HS", "PHD", "ANNA", "RESO"];
    const countries: string[] = ["DK", "SE", "NO", "FI"];
    const accessTypes: string[] = ["Access", "Paddington", "Grant"];

    const environments: Environment[] = [
        Environment.DEMO,
        Environment.STAGING,
        Environment.PRODUCTION,
      ];

    const agencyHandler = (agency: string) => {
        setActiveAgency(agency);
        props.setViewAll([] as string[]);
    };

    const countryHandler = (country: Country) => {
        setActiveCountry(country);
        props.setViewAll([] as string[]);
    };

    const environmentHandler = (environment: Environment) => {
        setActiveEnvironment(environment);
        props.setViewAll([] as string[]);
    };

    const accessTypeHandler = (statementType: string) => {
        if (statementType === "Access") {
            setActiveAccessType({
            has: StatementType.Access,
            give: StatementType.Paddington,
            });
        } else if (statementType === "Paddington") {
            setActiveAccessType({
            has: StatementType.Paddington,
            give: StatementType.Grant,
            });
        } else {
            setActiveAccessType({
            has: StatementType.Grant,
            give: StatementType.Grant,
            });
        }
        props.setViewAll([] as string[]);
    };

    return <Fragment>
        <div className={classes.dropdown_position}>
            <div className={classes.dropdown_single}>
                <label>Select Agency</label>
                <Dropdown
                    data={agencies}
                    type={"agencies"}
                    value={activeAgency}
                    label={"Agency"}
                    onChange={agencyHandler}
                    classes={classes.dropdown__layout}
                />
            </div>
            <div className={classes.dropdown_single}>
                <label>Select Country</label>
                <Dropdown
                    data={countries}
                    type={"agencies"}
                    value={activeCountry}
                    label={"Country"}
                    onChange={countryHandler}
                    classes={classes.dropdown__layout}
                />
            </div>
            <div className={classes.dropdown_single}>
                <label>Select Access type</label>
                <Dropdown
                    data={accessTypes}
                    type={"accessTypes"}
                    value={activeAccessType.has}
                    label={"Access Type"}
                    onChange={accessTypeHandler}
                    classes={classes.dropdown__layout}
                />
            </div>
            <div className={classes.dropdown_single}>
                <label>Select Environment</label>
                <Dropdown
                    data={environments}
                    type={"environment"}
                    value={activeEnvironment}
                    label={"Environment"}
                    onChange={environmentHandler}
                    classes={classes.dropdown__layout}
                />
            </div>
        </div>
    </Fragment>
}

export default ClientDropdown