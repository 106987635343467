import { Fragment, useContext } from "react";
import { ClientCodesResponse, Action, ResourceList, UserType } from "../../API";
import AccessContext from "../../Context/access-context";
import UserContext from "../../Context/user-context";
import { UserClientAccessType } from "../../Custom-Types";

const CmActions = (props: any) => {
  const { currentUserCampaignMappingAccess, userCampaignMappingAccess } =
    useContext(AccessContext);
  const { userType } = useContext(UserContext);

  const actionInput = (
    client: ClientCodesResponse,
    resource: string,
    action: Action
  ) => {
    let hasAccess: boolean = false;
    let giveAccess: boolean = false;

    // Check active user

    const userAccess = userCampaignMappingAccess.filter(
      (item: UserClientAccessType) => item.Client === client.Client
    );
    const resourceIndex = userAccess[0]?.Access.findIndex(
      (Ritem: ResourceList) => Ritem.Resource === resource
    );
    if (userAccess[0]?.Access[resourceIndex]?.Actions?.includes(action)) {
      hasAccess = true;
    }

    // Check logged in user

    const currentUserAccess = currentUserCampaignMappingAccess.filter(
      (item: UserClientAccessType) => item.Client === client.Client
    );
    const currentResourceIndex = currentUserAccess[0]?.Access.findIndex(
      (Ritem: ResourceList) => Ritem.Resource === resource
    );
    if (
      currentUserAccess[0]?.Access[currentResourceIndex]?.Actions?.includes(
        action
      )
    ) {
      console.log(currentUserAccess)
      giveAccess = true;
    }

    if (userType === UserType.GROUP) {
      if (hasAccess) {
        return (
          <input
            type="checkbox"
            checked={true}
            onChange={() =>
              props.inputOnChangeHandler(client, resource, action)
            }
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={false}
            onChange={() =>
              props.inputOnChangeHandler(client, resource, action)
            }
          />
        );
      }
    }

    if (hasAccess && giveAccess) {

      return (
        <input
          type="checkbox"
          checked={true}
          onChange={() => props.inputOnChangeHandler(client, resource, action)}
        />
      );
    } else if (hasAccess && !giveAccess) {
     
      return (
        <input
          type="checkbox"
          checked={true}
          disabled
          onChange={() => props.inputOnChangeHandler(client, resource, action)}
        />
      );
    } else if (!hasAccess && !giveAccess) {
   
      return (
        <input
          type="checkbox"
          checked={false}
          disabled
          onChange={() => props.inputOnChangeHandler(client, resource, action)}
        />
      );
    } else {
      
      return (
        <input
          type="checkbox"
          checked={false}
          onChange={() => props.inputOnChangeHandler(client, resource, action)}
        />
      );
    }
  };

  return (
    <Fragment>
      {actionInput(props.client, props.resource, props.action)}
    </Fragment>
  );
};

export default CmActions;
