import { Fragment, useContext, useRef } from "react";
import classes from "./AddNewGroup.module.css";
import Card from "../UI/Card";
import { createGroup } from "../../graphql/mutations";
import { CreateGroupMutation } from "../../API";
import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const AddNewGroup = (props: any) => {
  const groupNameInput: any = useRef();

  const cancelHandler = () => {
    props.setNewGroupActive(false);
  };

  const backdrop = () => {
    return <div className={classes.backdrop} onClick={cancelHandler}></div>;
  };

  const modalOverlay = () => {
    return (
      <Card className={classes.modal}>
        <div className={classes.modal__layout}>
          <h1>New Group</h1>
          <form onSubmit={submitHandler}>
            <div className={classes.input__layout}>
              <input required ref={groupNameInput} placeholder="Group Name" />
            </div>
            <div className={classes.button__layout}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </Card>
    );
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    createNewGroup();
    props.setNewGroupActive(false);
  };

  async function createNewGroup() {
    console.log("Calling createGroup");
    try {
      const response = (await clientAuth.graphql({
        query: createGroup,
        variables: {
          Group: groupNameInput.current.value,
        },
      })) as { data: CreateGroupMutation };
      if (response.data.createGroup?.StatusCode == "200") {
        props.setNewGroup(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Fragment>
      {backdrop()},{modalOverlay()}
    </Fragment>
  );
};

export default AddNewGroup;
