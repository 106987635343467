import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { UserClientServiceAccessRequest, UserClientServiceAccessType } from "../Custom-Types";
import { getUserAccessFromClient } from "../graphql/queries";
import { AccessType, Country, Environment, GetUserAccessFromClientQuery, StatementType } from "../API";
import ClientContext from "./client-context";
import { generateClient } from "aws-amplify/api";
const client = generateClient({authMode: "userPool"});

const PortalAccessContext = createContext({
    userPortalAccess: [] as UserClientServiceAccessType[],
    userPortalGrantableAccess: [] as UserClientServiceAccessType[],
    setPortalAccessRequest: (request: UserClientServiceAccessRequest) => {}
})

export const PortalAccessContextProvider = (props: any) => {

    const {activeAgency, activeAccessType, activeEnvironment, activeCountry} = useContext(ClientContext)

    const [userPortalAccess, setUserPortalAccess] = useState<UserClientServiceAccessType[]>([] as UserClientServiceAccessType[])
    const [portalAccessRequest, setPortalAccessRequest] = useState<UserClientServiceAccessRequest>({} as UserClientServiceAccessRequest)
    const [userPortalGrantableAccess, setUserPortalGrantableAccess] = useState<UserClientServiceAccessType[]>([] as UserClientServiceAccessType[])

    const fetchUserPortalAccess = useCallback(
        async (reset: boolean) => {
            console.log("Calling fetchUserPortalAccess")
            console.log(portalAccessRequest)
            try {
                const response = await client.graphql({
                    query: getUserAccessFromClient,
                    variables: {
                        ClientCode: portalAccessRequest.ClientCode as string,
                        ClientCountry: Country[portalAccessRequest.ClientCountry as keyof typeof Country],
                        Environment: Environment[portalAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
                        AccessType: StatementType[portalAccessRequest.StatementType.has as keyof typeof StatementType],
                        User: portalAccessRequest.User
                    }
                }) as {data: GetUserAccessFromClientQuery};
                console.log(response)
                if (reset) {
                    setUserPortalAccess([
                      {
                        Client: portalAccessRequest.ClientCode,
                        Access: response.data.getUserAccessFromClient,
                      },
                    ] as UserClientServiceAccessType[]);
                  } else {
                    setUserPortalAccess((prevState: any) => {
                      return [
                        ...prevState,
                        {
                          Client: portalAccessRequest.ClientCode,
                          Access: response.data.getUserAccessFromClient,
                        },
                      ];
                    });
                  }
            }catch(e) {
                console.log(e)
            }
        },
        [portalAccessRequest]
    )

    useEffect(() => {
        if (portalAccessRequest.ClientCode) {
          const arr = userPortalAccess.filter(
            (item: UserClientServiceAccessType) =>
              item.Client === portalAccessRequest.ClientCode
          )
          if (arr[0]?.Client !== portalAccessRequest.ClientCode) {
            fetchUserPortalAccess(false);
          }
        }
    },[portalAccessRequest, fetchUserPortalAccess])

    const fetchUserPortalGrantableAccess = useCallback(
      async (reset: boolean) => {
        console.log("Calling fetchUserPortalGrantableAccess");
        try {
          const response = await client.graphql({
            query: getUserAccessFromClient,
            variables: {
                ClientCode: portalAccessRequest.ClientCode as string,
                ClientCountry: Country[portalAccessRequest.ClientCountry as keyof typeof Country],
                Environment: Environment[portalAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
                AccessType: StatementType[portalAccessRequest.StatementType.give as keyof typeof StatementType],
            }
          }) as { data: GetUserAccessFromClientQuery};
          console.log(response)
          if (reset) {
              setUserPortalGrantableAccess([
                {
                  Client: portalAccessRequest.ClientCode,
                  Access: response.data.getUserAccessFromClient,
                },
              ] as UserClientServiceAccessType[]);
            } else {
              setUserPortalGrantableAccess((prevState: any) => {
                return [
                  ...prevState,
                  {
                    Client: portalAccessRequest.ClientCode,
                    Access: response.data.getUserAccessFromClient,
                  },
                ];
              });
            }
        }catch(e) {
            console.log(e)
        }
      }, [portalAccessRequest]
    )

    useEffect(() => {
      if (portalAccessRequest.ClientCode) {
        setUserPortalAccess([] as UserClientServiceAccessType[]);
        setUserPortalGrantableAccess([] as UserClientServiceAccessType[]);
      }
    }, [
      activeAccessType,
      activeAgency,
      activeCountry,
      activeEnvironment
    ]);

    useEffect(() => {
      if (portalAccessRequest.ClientCode) {
        const arr = userPortalGrantableAccess.filter(
          (item: UserClientServiceAccessType) =>
            item.Client === portalAccessRequest.ClientCode
        )
        if (arr[0]?.Client !== portalAccessRequest.ClientCode) {
          fetchUserPortalGrantableAccess(false);
        }
      }
  },[portalAccessRequest, fetchUserPortalGrantableAccess])

    return (
        <PortalAccessContext.Provider value={{
            userPortalAccess,
            userPortalGrantableAccess,
            setPortalAccessRequest
        }}>
            {props.children}
        </PortalAccessContext.Provider>
    )
}

export default PortalAccessContext