import { Fragment, useContext, useRef, useState } from "react";
import {
  AccessType,
  AddClientToOrganizationMutation,
  AddPortalGroupStatementMutation,
  Environment,
  StatementType,
} from "../../API";
import OrganizationContext from "../../Context/organization-context";
import {
  addClientToOrganization,
  addPortalGroupStatement,
} from "../../graphql/mutations";
import NewOrganization from "./NewOrganization";
import InitialAccess from "./InitialAccess";
import classes from "./Clients.module.css";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const Clients = (props: any) => {
  const clientCodeInput: any = useRef();
  const clientCountryInput: any = useRef();
  const organizationInput: any = useRef();

  const [groups, setGroups] = useState<[]>([] as any);
  const [accessTypes, setAccessTypes] = useState<[]>([] as any);
  const [environments, setEnvironments] = useState<[]>([] as any);

  const [invalidInputs, setInvalidInputs] = useState({
    clientCodeInputError: false,
    clientCountryInputError: false,
    clientOrganizationInputError: false,
    groupsInputError: false,
    accessTypesInputError: false,
    environmentsInputError: false,
  } as any);

  const [submitInvalid, setSubmitInvalid] = useState(false);

  const { allOrganizations } = useContext(OrganizationContext);

  const [clientResponse, setClientResponse] = useState({
    status: 0,
    message: "",
  });

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    const organization = allOrganizations.find((obj: any) => {
      return obj.Organization === organizationInput.current.value;
    });

    console.log(allOrganizations);
    console.log(organizationInput.current.value);

    console.log(clientCodeInput.current.value);
    console.log(clientCountryInput.current.value);
    console.log(organization);

    if (
      clientCountryInput.current.value !== "" &&
      clientCodeInput.current.value !== "" &&
      organizationInput.current.value !== "" &&
      groups.length !== 0 &&
      accessTypes.length !== 0 &&
      environments.length !== 0
    ) {
      addClient(organization);
      groups.forEach((group) => {
        accessTypes.forEach((act) => {
          environments.forEach((env) => {
            grantInitialGroupAccess(group, act, env);
          });
        });
      });

      clientCodeInput.current.value = "";
      clientCountryInput.current.value = "";
      setGroups([]);
      setAccessTypes([]);
      setEnvironments([]);
    } else {
      setSubmitInvalid(true);
      if (groups.length === 0) {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, groupsInputError: true };
        });
      }
      if (accessTypes.length === 0) {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, accessTypesInputError: true };
        });
      }
      if (clientCountryInput.current.value === "") {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, clientCountryInputError: true };
        });
      }
      if (organizationInput.current.value === "") {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, clientOrganizationInputError: true };
        });
      }
    }
  };

  async function grantInitialGroupAccess(
    group: string,
    act: string,
    env: string
  ) {
    console.log("calling grantInitialGroupAccess");
    console.log(groups);
    console.log(clientCodeInput.current.value);
    console.log(clientCountryInput.current.value);

    try {
      const response = (await clientAuth.graphql({
        query: addPortalGroupStatement,
        variables: {
          ClientCode: clientCodeInput.current.value,
          ClientCountry: clientCountryInput.current.value,
          Group: group,
          StatementType: StatementType[act as keyof typeof StatementType],
          Environment: Environment[env.toUpperCase() as keyof typeof Environment],
          AccessType: AccessType.FULL,
        },
    
      })) as { data: AddPortalGroupStatementMutation };
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  async function addClient(organization: any) {
    console.log("Calling adClient");
    try {
      const response = (await clientAuth.graphql({
        query: addClientToOrganization,
        variables: {
          ClientCode: clientCodeInput.current.value,
          ClientCountry: clientCountryInput.current.value,
          Organization_id: organization.OrganizationId,
        },
    
      })) as { data: AddClientToOrganizationMutation };
      console.log(response);
      setClientResponse((prevState: any) => {
        return {
          ...prevState,
          status: response.data.addClientToOrganization?.StatusCode,
          message: response.data.addClientToOrganization?.Message,
        };
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Fragment>
      <div style={{ margin: "10px" }}>
        <h1>Welcome to Paddington</h1>
        <br></br>

        <h2>Add Client to Organization</h2>
        {submitInvalid && (
          <p style={{ color: "red" }}>Some fields are invalid</p>
        )}
        <form onSubmit={onSubmitHandler}>
          <input ref={clientCodeInput as any} placeholder="Client Code"></input>
          <select
            className={
              invalidInputs.clientCountryInputError
                ? classes.countryDropDownError
                : ""
            }
            ref={clientCountryInput as any}
          >
            <option value="" disabled selected hidden>
              Country
            </option>
            <option>DK</option>
            <option>SE</option>
            <option>NO</option>
            <option>FI</option>
          </select>
          <select
            className={
              invalidInputs.clientOrganizationInputError
                ? classes.countryDropDownError
                : ""
            }
            ref={organizationInput}
          >
            <option value="" disabled selected hidden>
              Organizations
            </option>
            {allOrganizations.map((item: any) => (
              <option key={`${item.OrganizationId}-${item.Organization}`}>
                {item.Organization}
              </option>
            ))}
            <option>Telenor</option>
          </select>
          <br></br>
          <p>
            <b>Select initial access to this client</b>
          </p>
          <InitialAccess
            groups={groups}
            setGroups={setGroups}
            accessTypes={accessTypes}
            setAccessTypes={setAccessTypes}
            environments={environments}
            setEnvironments={setEnvironments}
            invalidInputs={invalidInputs}
          />
          <button
            type="submit"
            style={{
              width: "120px",
              height: "40px",
              backgroundColor: "blue",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Add Client
          </button>
        </form>
        {clientResponse.status == 200 && (
          <p style={{ color: "green" }}>
            <b>{clientResponse.message}</b>
          </p>
        )}
        <br></br>
        <br></br>
        <br></br>

        <h2>Create New Organization</h2>
        <NewOrganization />
      </div>
    </Fragment>
  );
};

export default Clients;
