import React, { Fragment, useEffect, useState } from "react";
import logo from "./logo.svg";
import {Amplify}from "aws-amplify";
import AccessBoard from "./components/AccessBoard/AccessBoard";
import DashboardModule from "./components/Dashboards/DashboardModule";
import Login from "./components/Login/Login";
import "./App.css";
import TopHeader from "./components/Header/TopHeader";
import Clients from "./components/ClientAccessBoard/Clients";
import Group from "./components/Groups/Group";
import Users from "./components/Users/Users";
import paddingtonImage from "./images/paddington.png";

import amplifyconfig from "./amplifyconfiguration.json";

const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_WEB_CLIENT_ID,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_GRAPHQL,
} = process.env;

amplifyconfig.oauth.redirectSignIn = REACT_APP_REDIRECT_SIGN_IN as string;
amplifyconfig.oauth.redirectSignOut = REACT_APP_REDIRECT_SIGN_OUT as string;

const amplifyConf = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL,
  aws_project_region: "eu-west-1",
  aws_appsync_authenticationType: "AWS_IAM",
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: "authentication.auth.eu-west-1.amazoncognito.com",
      scope: ["openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
      responseType: "token",
    },
  },
};

Amplify.configure(amplifyconfig);

function App() {
  const [module, setModule] = useState("");

  return (
    <Fragment>
      <TopHeader setModule={setModule} />
      <Login />
      {module == "Dashboards" && <DashboardModule />}
      {module == "Clients" && <Clients />}
      {module == "Groups" && <Group />}
      {module == "Users" && <Users />}

      {module === "" && (
        <div style={{ textAlign: "center", marginTop: "13rem" }}>
          <img src={paddingtonImage} alt="Example" />
        </div>
      )}
    </Fragment>
  );
}

export default App;