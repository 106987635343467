import { Fragment, useContext } from "react"
import AccessContext from "../../../Context/access-context";
import { projectAccessResponse, projectResources, projectServices } from "../../../API";
import classes from "./OMGPortalAccess.module.css"
import OMGPortalAction from "./OMGPortalAction";

const OMGPortalAccess = (props: any) => {

    const {projectAccess} = useContext(AccessContext);

    return <Fragment>
        {projectAccess.map((project: projectAccessResponse) => (<div key={project?.Level}>
            {project.Services?.map((service: projectServices | null) => (
                <div key={`${props.clientKey} - ${service?.Service}`} className={classes.services}>
                <p className={classes.service}><b>{service?.Service}</b></p>
                {service?.Resources?.map((resource: projectResources | null) => (
                <div key={`${props.clientKey} - ${service?.Service} - ${resource?.Resource}`} className={classes.resources}>
                    <p className={classes.resource}>{resource?.Resource}</p>
                    <div className={classes.actions_container}>
                        { resource?.Actions?.map((action: string | null) => (
                            <OMGPortalAction key={`${props.client} - ${props.country} - ${service?.Service} - ${resource?.Resource} - ${action}`} 
                            action={action} 
                            resource={resource?.Resource}
                            service={service?.Service}
                            client={props.client}
                            country={props.country}
                            response={props.response} 
                            setResponse={props.setResponse}
                            username={props.username}
                            />))
                        }
                    </div>
                   
                </div>))}
            </div>))}
        </div>))}
    </Fragment>
}

export default OMGPortalAccess