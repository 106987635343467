import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import EnvironmentContext from "./environment-context";
import {
  getOrganizationsResponse,
  GetOrganizationsQuery,
  ListAllOrganizationsQuery,
  Environment,
} from "../API";
import { getOrganizations, listAllOrganizations } from "../graphql/queries";

import { generateClient } from "aws-amplify/api";
const client = generateClient({authMode: "userPool"});

const OrganizationContext = createContext({
  organizations: [] as getOrganizationsResponse[],
  activeOrganization: {} as getOrganizationsResponse,
  allOrganizations: [] as getOrganizationsResponse[],
  newOrganizationActive: false,
  setOrganizations: (args: getOrganizationsResponse[]) => {},
  setActiveOrganization: (args: getOrganizationsResponse) => {},
  setNewOrganizationActive: (args: any) => {},
});

export const OrganizationContextProvider = (props: any) => {
  const [organizations, setOrganizations] = useState<any>(
    [] as getOrganizationsResponse[]
  );
  const [activeOrganization, setActiveOrganization] = useState(
    {} as getOrganizationsResponse
  );

  const [allOrganizations, setAllorganizations] = useState<any>(
    [] as getOrganizationsResponse[]
  );

  const [newOrganizationActive, setNewOrganizationActive] = useState(false);

  const { activeEnvironment } = useContext(EnvironmentContext);

  const fetchOrganizations = useCallback(async () => {
    console.log("Calling getOrganizations");
    try {
      const responseList = (await client.graphql({
        query: getOrganizations,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
        },
      })) as { data: GetOrganizationsQuery };

      setOrganizations(responseList.data.getOrganizations);
    } catch (e) {
      console.log(`Failed to get organizations - ${e}`);
    }
  }, [activeEnvironment]);

  const fetchAllOrganizations = useCallback(async () => {
    console.log("Calling ListAllOrganizations");
    try {
      const responseList = (await client.graphql({
        query: listAllOrganizations
      })) as { data: ListAllOrganizationsQuery };
      console.log(responseList);
      setAllorganizations(responseList.data.listAllOrganizations);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchAllOrganizations();
  }, []);

  useEffect(() => {
    if (activeEnvironment) {
      fetchOrganizations();
    }
  }, [fetchOrganizations]);

  return (
    <OrganizationContext.Provider
      value={{
        organizations,
        activeOrganization,
        allOrganizations,
        newOrganizationActive,
        setOrganizations,
        setActiveOrganization,
        setNewOrganizationActive,
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContext;
