import { createContext, useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { logActivity } from "../graphql/mutations";
import { Environment, LogActivityMutation } from "../API";
import { AuthUser, fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";

const AuthContext = createContext({
  user: undefined as AuthUser |  null | undefined,
});

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState<AuthUser | null | undefined>( undefined);
  const [postLoginExecuted, setPostLoginExecuted] = useState(false);

  useEffect(() => {
    getUser().then((userData: any) => setUser(userData));
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
          getUser();
          setPostLoginExecuted(true);
          break;
        case "signInWithRedirect_failure":
        case "customOAuthState":
          console.log("Sign in failure");
          break;
        case "signedOut":
          setUser(null);
      }
    });

    getUser()
    return unsubscribe;
  }, []);

  const getUser = async() => {
    const currentUser = await getCurrentUser();
    setUser(currentUser);
  }

  useEffect(() => {
    if (postLoginExecuted) {
      logLogin()
    }

  },[postLoginExecuted])

  async function logLogin() {
    const client = generateClient({authMode: "iam", headers: {"x-custom-auth-header": "TESTTEST"}});
    const credentials = await fetchAuthSession();
    console.log(credentials)
    try {
      const response = await client.graphql({
        query: logActivity,
        variables: {
          Username: user?.username as string,
          Action: "LOGIN",
          Environment: Environment.PRODUCTION,
          Service: "PADDINGTON UI",
          Url: "https://staging-paddington.annalect-data.info/"
        }
      }) as {data: LogActivityMutation}
      console.log(response)
    }catch (e) {
      console.log(e)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
