/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addExternalUser = /* GraphQL */ `mutation AddExternalUser(
  $Email: String!
  $Name: String!
  $Family_name: String!
  $Organization_id: String!
  $Environment: Environment!
  $Agency: String
  $WelcomeUrl: String
) {
  addExternalUser(
    Email: $Email
    Name: $Name
    Family_name: $Family_name
    Organization_id: $Organization_id
    Environment: $Environment
    Agency: $Agency
    WelcomeUrl: $WelcomeUrl
  ) {
    Attributes {
      FamilyName
      Name
      email
      __typename
    }
    Status
    StatusCode
    UserType
    Username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddExternalUserMutationVariables,
  APITypes.AddExternalUserMutation
>;
export const addInternalUser = /* GraphQL */ `mutation AddInternalUser($Email: String!, $Environment: Environment!) {
  addInternalUser(Email: $Email, Environment: $Environment) {
    Attributes {
      FamilyName
      Name
      email
      __typename
    }
    Status
    StatusCode
    UserType
    Username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddInternalUserMutationVariables,
  APITypes.AddInternalUserMutation
>;
export const addAccessStatement = /* GraphQL */ `mutation AddAccessStatement(
  $Action: Action!
  $ClientCode: String!
  $ClientCountry: Country!
  $Environment: Environment!
  $Resource: String!
  $Service: Service!
  $Username: String!
  $StatementType: StatementType!
) {
  addAccessStatement(
    Action: $Action
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    Resource: $Resource
    Service: $Service
    Username: $Username
    StatementType: $StatementType
  ) {
    Message
    StatementId
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAccessStatementMutationVariables,
  APITypes.AddAccessStatementMutation
>;
export const removeAccessStatement = /* GraphQL */ `mutation RemoveAccessStatement(
  $Action: Action!
  $ClientCode: String!
  $ClientCountry: Country!
  $Environment: Environment!
  $Resource: String!
  $Service: Service!
  $Username: String!
  $StatementType: StatementType!
) {
  removeAccessStatement(
    Action: $Action
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    Resource: $Resource
    Service: $Service
    Username: $Username
    StatementType: $StatementType
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveAccessStatementMutationVariables,
  APITypes.RemoveAccessStatementMutation
>;
export const addClientToOrganization = /* GraphQL */ `mutation AddClientToOrganization(
  $ClientCode: String!
  $ClientCountry: String!
  $Organization_id: String!
) {
  addClientToOrganization(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Organization_id: $Organization_id
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddClientToOrganizationMutationVariables,
  APITypes.AddClientToOrganizationMutation
>;
export const addNewOrganization = /* GraphQL */ `mutation AddNewOrganization(
  $ClientCode: String!
  $ClientCountry: String!
  $ClientOrganization: String!
) {
  addNewOrganization(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    ClientOrganization: $ClientOrganization
  ) {
    PK
    SK
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddNewOrganizationMutationVariables,
  APITypes.AddNewOrganizationMutation
>;
export const addGroupStatement = /* GraphQL */ `mutation AddGroupStatement(
  $Action: Action!
  $ClientCode: String!
  $ClientCountry: Country!
  $Environment: Environment!
  $Resource: String!
  $Service: Service!
  $Group: String!
  $StatementType: StatementType!
) {
  addGroupStatement(
    Action: $Action
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    Resource: $Resource
    Service: $Service
    Group: $Group
    StatementType: $StatementType
  ) {
    Message
    StatementId
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddGroupStatementMutationVariables,
  APITypes.AddGroupStatementMutation
>;
export const addPortalGroupStatement = /* GraphQL */ `mutation AddPortalGroupStatement(
  $Group: String!
  $ClientCode: String!
  $ClientCountry: String!
  $Environment: Environment!
  $StatementType: StatementType!
  $AccessType: AccessType!
) {
  addPortalGroupStatement(
    Group: $Group
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    StatementType: $StatementType
    AccessType: $AccessType
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPortalGroupStatementMutationVariables,
  APITypes.AddPortalGroupStatementMutation
>;
export const addUserToGroup = /* GraphQL */ `mutation AddUserToGroup(
  $Email: String!
  $FamilyName: String!
  $Name: String!
  $Group: String!
  $Username: String!
) {
  addUserToGroup(
    Email: $Email
    FamilyName: $FamilyName
    Name: $Name
    Group: $Group
    Username: $Username
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddUserToGroupMutationVariables,
  APITypes.AddUserToGroupMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup($Group: String!) {
  createGroup(Group: $Group) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateExternalUser = /* GraphQL */ `mutation UpdateExternalUser($Username: String!, $ResendInvitation: Boolean!) {
  updateExternalUser(Username: $Username, ResendInvitation: $ResendInvitation) {
    Status
    StatusCode
    Username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExternalUserMutationVariables,
  APITypes.UpdateExternalUserMutation
>;
export const logActivity = /* GraphQL */ `mutation LogActivity(
  $Username: String!
  $Action: String!
  $Environment: Environment!
  $Service: String!
  $Url: String
  $Ip: String
) {
  logActivity(
    Username: $Username
    Action: $Action
    Environment: $Environment
    Service: $Service
    Url: $Url
    Ip: $Ip
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LogActivityMutationVariables,
  APITypes.LogActivityMutation
>;
