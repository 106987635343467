/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum Environment {
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
  DEMO = "DEMO",
}


export type CognitoResponseType = {
  __typename: "CognitoResponseType",
  Attributes?: CognitoAttribute | null,
  Status: string,
  StatusCode: number,
  UserType: UserType,
  Username: string,
};

export type CognitoAttribute = {
  __typename: "CognitoAttribute",
  FamilyName: string,
  Name: string,
  email: string,
};

export enum UserType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
  PROGRAMMATIC = "PROGRAMMATIC",
  COGNITO = "COGNITO",
  GROUP = "GROUP",
  TEST = "TEST",
  NONE = "NONE",
}


export enum Action {
  READ = "READ",
  WRITE = "WRITE",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  PUBLISH = "PUBLISH",
  UNPUBLISH = "UNPUBLISH",
}


export enum Country {
  DK = "DK",
  SE = "SE",
  NO = "NO",
  FI = "FI",
  ALL = "ALL",
  NC = "NC",
  GL = "GL",
}


export enum Service {
  Dashboards = "Dashboards",
  CM = "CM",
  OMDPortal = "OMDPortal",
  ONBOARD = "ONBOARD",
}


export enum StatementType {
  Access = "Access",
  Paddington = "Paddington",
  Grant = "Grant",
}


export type addAccessStatementResponse = {
  __typename: "addAccessStatementResponse",
  Message?: string | null,
  StatementId?: string | null,
  StatusCode?: number | null,
};

export type AccessStatementResponse = {
  __typename: "AccessStatementResponse",
  Message?: string | null,
  StatusCode?: string | null,
};

export type oldResponse = {
  __typename: "oldResponse",
  PK?: string | null,
  SK?: string | null,
};

export enum AccessType {
  FULL = "FULL",
  READ = "READ",
  NONE = "NONE",
}


export type updateExternalUserResponse = {
  __typename: "updateExternalUserResponse",
  Status?: string | null,
  StatusCode?: number | null,
  Username?: string | null,
};

export type getOrganizationsResponse = {
  __typename: "getOrganizationsResponse",
  Organization?: string | null,
  OrganizationId?: string | null,
};

export type getClientsResponse = {
  __typename: "getClientsResponse",
  ClientCodes?: Array< string | null > | null,
  Country?: Country | null,
  OrganizationId?: string | null,
};

export type getUserAccessStatementsResponse = {
  __typename: "getUserAccessStatementsResponse",
  Clients?:  Array<ClientResponse | null > | null,
  Users?:  Array<UserResponse | null > | null,
};

export type ClientResponse = {
  __typename: "ClientResponse",
  ClientCode?: string | null,
  ClientCountry?: string | null,
};

export type UserResponse = {
  __typename: "UserResponse",
  Username?: string | null,
  Email?: string | null,
  Name?: string | null,
  Family_name?: string | null,
  Statements?:  Array<StatementResponse | null > | null,
};

export type StatementResponse = {
  __typename: "StatementResponse",
  Action?: Array< string | null > | null,
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Environment?: string | null,
  Resource?: string | null,
  Service?: string | null,
};

export type getUserResponse = {
  __typename: "getUserResponse",
  Clients?:  Array<ClientResponse | null > | null,
  Users?:  Array<SingleUserResponse | null > | null,
};

export type SingleUserResponse = {
  __typename: "SingleUserResponse",
  Username?: string | null,
  Email?: string | null,
  Name?: string | null,
  Family_name?: string | null,
  Statements?:  Array<singleUserStatementResponse | null > | null,
};

export type singleUserStatementResponse = {
  __typename: "singleUserStatementResponse",
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Resources?: Array< string | null > | null,
};

export type getDashboardsResponse = {
  __typename: "getDashboardsResponse",
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Resources?:  Array<ResourceResponse | null > | null,
};

export type ResourceResponse = {
  __typename: "ResourceResponse",
  Dashboard?: string | null,
  Access?: boolean | null,
};

export type GroupMemeberInfo = {
  __typename: "GroupMemeberInfo",
  Username?: string | null,
  Name?: string | null,
  FamilyName?: string | null,
  Email?: string | null,
};

export type UserInfoResponse = {
  __typename: "UserInfoResponse",
  Users?:  Array<UserInfo | null > | null,
  NextToken?: string | null,
};

export type UserInfo = {
  __typename: "UserInfo",
  Agency?: string | null,
  Email?: string | null,
  FamilyName?: string | null,
  Name?: string | null,
  UserName?: string | null,
  UserType?: UserType | null,
};

export type getAllDashboardsResponse = {
  __typename: "getAllDashboardsResponse",
  PK?: string | null,
  SK?: string | null,
  ClientCodes?:  Array<ClientCodesResponse | null > | null,
  ClientSpecific?: boolean | null,
  DashboardRoute?: string | null,
};

export type ClientCodesResponse = {
  __typename: "ClientCodesResponse",
  Client?: string | null,
  Country?: string | null,
};

export type getAllDashboardsV2Response = {
  __typename: "getAllDashboardsV2Response",
  DashboardId?: string | null,
  DashboardName?: string | null,
  DashboardRoute?: string | null,
  PK?: string | null,
  Resource?: string | null,
  Service?: string | null,
};

export type ClientList = {
  __typename: "ClientList",
  User?: string | null,
  Statements?:  Array<ClientStatement | null > | null,
};

export type ClientStatement = {
  __typename: "ClientStatement",
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Service?: string | null,
  Environment?: string | null,
  Resources?:  Array<ResourceList | null > | null,
};

export type ResourceList = {
  __typename: "ResourceList",
  Resource?: string | null,
  Actions?: Array< string | null > | null,
};

export type CampaignMappingClientInfoResponse = {
  __typename: "CampaignMappingClientInfoResponse",
  clientName?: string | null,
  agency?: string | null,
  parentCode?: string | null,
  marathonId?: string | null,
};

export type getDashboardClientsResponse = {
  __typename: "getDashboardClientsResponse",
  ActualCountry?: string | null,
  Country?: string | null,
  Client?: string | null,
  Production?: boolean | null,
  Staging?: boolean | null,
  Status?: string | null,
};

export type Clients = {
  ClientCode?: string | null,
  ClientCountry?: string | null,
};

export type dashboardAccessResponse = {
  __typename: "dashboardAccessResponse",
  Action?: Action | null,
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Resource?: string | null,
  Service?: string | null,
  Username?: string | null,
  Email?: string | null,
  Name?: string | null,
  FamilyName?: string | null,
};

export enum AccessLevel {
  FULL_ACCESS = "FULL_ACCESS",
  READ_ACCESS = "READ_ACCESS",
  READ_PLUS_ACCESS = "READ_PLUS_ACCESS",
}


export type projectAccessResponse = {
  __typename: "projectAccessResponse",
  Level?: string | null,
  Priority?: number | null,
  Services?:  Array<projectServices | null > | null,
};

export type projectServices = {
  __typename: "projectServices",
  Service?: string | null,
  Resources?:  Array<projectResources | null > | null,
};

export type projectResources = {
  __typename: "projectResources",
  Resource?: string | null,
  Actions?: Array< string | null > | null,
};

export type ServiceList = {
  __typename: "ServiceList",
  Service?: string | null,
  Resources?:  Array<ResourceList | null > | null,
};

export type AddExternalUserMutationVariables = {
  Email: string,
  Name: string,
  Family_name: string,
  Organization_id: string,
  Environment: Environment,
  Agency?: string | null,
  WelcomeUrl?: string | null,
};

export type AddExternalUserMutation = {
  addExternalUser?:  {
    __typename: "CognitoResponseType",
    Attributes?:  {
      __typename: "CognitoAttribute",
      FamilyName: string,
      Name: string,
      email: string,
    } | null,
    Status: string,
    StatusCode: number,
    UserType: UserType,
    Username: string,
  } | null,
};

export type AddInternalUserMutationVariables = {
  Email: string,
  Environment: Environment,
};

export type AddInternalUserMutation = {
  addInternalUser?:  {
    __typename: "CognitoResponseType",
    Attributes?:  {
      __typename: "CognitoAttribute",
      FamilyName: string,
      Name: string,
      email: string,
    } | null,
    Status: string,
    StatusCode: number,
    UserType: UserType,
    Username: string,
  } | null,
};

export type AddAccessStatementMutationVariables = {
  Action: Action,
  ClientCode: string,
  ClientCountry: Country,
  Environment: Environment,
  Resource: string,
  Service: Service,
  Username: string,
  StatementType: StatementType,
};

export type AddAccessStatementMutation = {
  addAccessStatement?:  {
    __typename: "addAccessStatementResponse",
    Message?: string | null,
    StatementId?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type RemoveAccessStatementMutationVariables = {
  Action: Action,
  ClientCode: string,
  ClientCountry: Country,
  Environment: Environment,
  Resource: string,
  Service: Service,
  Username: string,
  StatementType: StatementType,
};

export type RemoveAccessStatementMutation = {
  removeAccessStatement?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type AddClientToOrganizationMutationVariables = {
  ClientCode: string,
  ClientCountry: string,
  Organization_id: string,
};

export type AddClientToOrganizationMutation = {
  addClientToOrganization?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type AddNewOrganizationMutationVariables = {
  ClientCode: string,
  ClientCountry: string,
  ClientOrganization: string,
};

export type AddNewOrganizationMutation = {
  addNewOrganization?:  {
    __typename: "oldResponse",
    PK?: string | null,
    SK?: string | null,
  } | null,
};

export type AddGroupStatementMutationVariables = {
  Action: Action,
  ClientCode: string,
  ClientCountry: Country,
  Environment: Environment,
  Resource: string,
  Service: Service,
  Group: string,
  StatementType: StatementType,
};

export type AddGroupStatementMutation = {
  addGroupStatement?:  {
    __typename: "addAccessStatementResponse",
    Message?: string | null,
    StatementId?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type AddPortalGroupStatementMutationVariables = {
  Group: string,
  ClientCode: string,
  ClientCountry: string,
  Environment: Environment,
  StatementType: StatementType,
  AccessType: AccessType,
};

export type AddPortalGroupStatementMutation = {
  addPortalGroupStatement?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type AddUserToGroupMutationVariables = {
  Email: string,
  FamilyName: string,
  Name: string,
  Group: string,
  Username: string,
};

export type AddUserToGroupMutation = {
  addUserToGroup?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type CreateGroupMutationVariables = {
  Group: string,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type UpdateExternalUserMutationVariables = {
  Username: string,
  ResendInvitation: boolean,
};

export type UpdateExternalUserMutation = {
  updateExternalUser?:  {
    __typename: "updateExternalUserResponse",
    Status?: string | null,
    StatusCode?: number | null,
    Username?: string | null,
  } | null,
};

export type LogActivityMutationVariables = {
  Username: string,
  Action: string,
  Environment: Environment,
  Service: string,
  Url?: string | null,
  Ip?: string | null,
};

export type LogActivityMutation = {
  logActivity?:  {
    __typename: "AccessStatementResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type GetOrganizationsQueryVariables = {
  Environment: Environment,
};

export type GetOrganizationsQuery = {
  getOrganizations?:  Array< {
    __typename: "getOrganizationsResponse",
    Organization?: string | null,
    OrganizationId?: string | null,
  } | null > | null,
};

export type GetClientsQueryVariables = {
  Organization_id: string,
};

export type GetClientsQuery = {
  getClients?:  Array< {
    __typename: "getClientsResponse",
    ClientCodes?: Array< string | null > | null,
    Country?: Country | null,
    OrganizationId?: string | null,
  } | null > | null,
};

export type GetUserAccessStatementsQueryVariables = {
  Environment: Environment,
  Organization_id: string,
  Resource?: string | null,
  UserType: UserType,
};

export type GetUserAccessStatementsQuery = {
  getUserAccessStatements?:  {
    __typename: "getUserAccessStatementsResponse",
    Clients?:  Array< {
      __typename: "ClientResponse",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    Users?:  Array< {
      __typename: "UserResponse",
      Username?: string | null,
      Email?: string | null,
      Name?: string | null,
      Family_name?: string | null,
      Statements?:  Array< {
        __typename: "StatementResponse",
        Action?: Array< string | null > | null,
        ClientCode?: string | null,
        ClientCountry?: string | null,
        Environment?: string | null,
        Resource?: string | null,
        Service?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetUserQueryVariables = {
  Environment: Environment,
  Organization_id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "getUserResponse",
    Clients?:  Array< {
      __typename: "ClientResponse",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    Users?:  Array< {
      __typename: "SingleUserResponse",
      Username?: string | null,
      Email?: string | null,
      Name?: string | null,
      Family_name?: string | null,
      Statements?:  Array< {
        __typename: "singleUserStatementResponse",
        ClientCode?: string | null,
        ClientCountry?: string | null,
        Resources?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetDashboardsQueryVariables = {
  Organization_id: string,
  Environment: Environment,
};

export type GetDashboardsQuery = {
  getDashboards?:  Array< {
    __typename: "getDashboardsResponse",
    ClientCode?: string | null,
    ClientCountry?: string | null,
    Resources?:  Array< {
      __typename: "ResourceResponse",
      Dashboard?: string | null,
      Access?: boolean | null,
    } | null > | null,
  } | null > | null,
};

export type ListAllOrganizationsQueryVariables = {
};

export type ListAllOrganizationsQuery = {
  listAllOrganizations?:  Array< {
    __typename: "getOrganizationsResponse",
    Organization?: string | null,
    OrganizationId?: string | null,
  } | null > | null,
};

export type GetGroupsQueryVariables = {
};

export type GetGroupsQuery = {
  getGroups?: Array< string | null > | null,
};

export type GetGroupQueryVariables = {
  Group: string,
  Environment: Environment,
  Organization_id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "getUserAccessStatementsResponse",
    Clients?:  Array< {
      __typename: "ClientResponse",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    Users?:  Array< {
      __typename: "UserResponse",
      Username?: string | null,
      Email?: string | null,
      Name?: string | null,
      Family_name?: string | null,
      Statements?:  Array< {
        __typename: "StatementResponse",
        Action?: Array< string | null > | null,
        ClientCode?: string | null,
        ClientCountry?: string | null,
        Environment?: string | null,
        Resource?: string | null,
        Service?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetGroupMembersQueryVariables = {
  Group: string,
};

export type GetGroupMembersQuery = {
  getGroupMembers?:  Array< {
    __typename: "GroupMemeberInfo",
    Username?: string | null,
    Name?: string | null,
    FamilyName?: string | null,
    Email?: string | null,
  } | null > | null,
};

export type GetUsersQueryVariables = {
  UserType: UserType,
  nextToken?: string | null,
  Limit?: number | null,
  Username?: string | null,
};

export type GetUsersQuery = {
  getUsers?:  {
    __typename: "UserInfoResponse",
    Users?:  Array< {
      __typename: "UserInfo",
      Agency?: string | null,
      Email?: string | null,
      FamilyName?: string | null,
      Name?: string | null,
      UserName?: string | null,
      UserType?: UserType | null,
    } | null > | null,
    NextToken?: string | null,
  } | null,
};

export type GetAllDashboardsQueryVariables = {
  PK?: string | null,
  SK?: string | null,
};

export type GetAllDashboardsQuery = {
  getAllDashboards?:  Array< {
    __typename: "getAllDashboardsResponse",
    PK?: string | null,
    SK?: string | null,
    ClientCodes?:  Array< {
      __typename: "ClientCodesResponse",
      Client?: string | null,
      Country?: string | null,
    } | null > | null,
    ClientSpecific?: boolean | null,
    DashboardRoute?: string | null,
  } | null > | null,
};

export type GetAllDashboards_v2QueryVariables = {
};

export type GetAllDashboards_v2Query = {
  getAllDashboards_v2?:  Array< {
    __typename: "getAllDashboardsV2Response",
    DashboardId?: string | null,
    DashboardName?: string | null,
    DashboardRoute?: string | null,
    PK?: string | null,
    Resource?: string | null,
    Service?: string | null,
  } | null > | null,
};

export type GetStatementsQueryVariables = {
  User?: string | null,
  Environment: Environment,
  Service: string,
  Resource: string,
  StatementType: StatementType,
  FromGroup?: string | null,
};

export type GetStatementsQuery = {
  getStatements?:  {
    __typename: "ClientList",
    User?: string | null,
    Statements?:  Array< {
      __typename: "ClientStatement",
      ClientCode?: string | null,
      ClientCountry?: string | null,
      Service?: string | null,
      Environment?: string | null,
      Resources?:  Array< {
        __typename: "ResourceList",
        Resource?: string | null,
        Actions?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetCampaignMappingClientsQueryVariables = {
  country: Country,
  agency: string,
};

export type GetCampaignMappingClientsQuery = {
  getCampaignMappingClients?:  Array< {
    __typename: "ClientCodesResponse",
    Client?: string | null,
    Country?: string | null,
  } | null > | null,
};

export type GetStatementsFromClientQueryVariables = {
  ClientCode: string,
  ClientCountry: Country,
  Service: string,
  Environment: Environment,
  StatementType: StatementType,
  User?: string | null,
};

export type GetStatementsFromClientQuery = {
  getStatementsFromClient?:  Array< {
    __typename: "ResourceList",
    Resource?: string | null,
    Actions?: Array< string | null > | null,
  } | null > | null,
};

export type GetCampaignMappingClientInfoQueryVariables = {
  country: Country,
  client: string,
};

export type GetCampaignMappingClientInfoQuery = {
  getCampaignMappingClientInfo?:  {
    __typename: "CampaignMappingClientInfoResponse",
    clientName?: string | null,
    agency?: string | null,
    parentCode?: string | null,
    marathonId?: string | null,
  } | null,
};

export type GetDashboardClientsQueryVariables = {
  Resource: string,
  Service: string,
};

export type GetDashboardClientsQuery = {
  getDashboardClients?:  Array< {
    __typename: "getDashboardClientsResponse",
    ActualCountry?: string | null,
    Country?: string | null,
    Client?: string | null,
    Production?: boolean | null,
    Staging?: boolean | null,
    Status?: string | null,
  } | null > | null,
};

export type GetDashboardAccessQueryVariables = {
  Resource: string,
  Environment: Environment,
  StatementType: StatementType,
  UserType: UserType,
  Clients: Array< Clients | null >,
};

export type GetDashboardAccessQuery = {
  getDashboardAccess?:  Array< {
    __typename: "dashboardAccessResponse",
    Action?: Action | null,
    ClientCode?: string | null,
    ClientCountry?: string | null,
    Resource?: string | null,
    Service?: string | null,
    Username?: string | null,
    Email?: string | null,
    Name?: string | null,
    FamilyName?: string | null,
  } | null > | null,
};

export type GetProjectAccessQueryVariables = {
  Level: AccessLevel,
};

export type GetProjectAccessQuery = {
  getProjectAccess?:  Array< {
    __typename: "projectAccessResponse",
    Level?: string | null,
    Priority?: number | null,
    Services?:  Array< {
      __typename: "projectServices",
      Service?: string | null,
      Resources?:  Array< {
        __typename: "projectResources",
        Resource?: string | null,
        Actions?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetUserAccessFromClientQueryVariables = {
  ClientCode: string,
  ClientCountry: Country,
  Environment: Environment,
  AccessType: StatementType,
  User?: string | null,
};

export type GetUserAccessFromClientQuery = {
  getUserAccessFromClient?:  Array< {
    __typename: "ServiceList",
    Service?: string | null,
    Resources?:  Array< {
      __typename: "ResourceList",
      Resource?: string | null,
      Actions?: Array< string | null > | null,
    } | null > | null,
  } | null > | null,
};
