import { Fragment, useContext, useEffect, useRef, useState } from "react";
import classes from "./DashboardModule.module.css";
import DashboardList from "./DashboardList";
import ResourceContext from "../../Context/resource-context";
import DashboardSearch from "./DashboardSearch";
import DashboardUsers from "./DashboardUsers";
import {
  ClientResponse,
  Clients,
  dashboardAccessResponse,
  getAllDashboardsResponse,
  getAllDashboardsV2Response,
  getDashboardClientsResponse,
} from "../../API";
import UserContext from "../../Context/user-context";
import ClientContext from "../../Context/client-context";

const DashboardModule = (props: any) => {
  const searchInput: any = useRef();

  const {
    allDashboards_v2,
    setActiveService,
    setSelectedDashboard_v2,
    dashboardClients,
  } = useContext(ResourceContext);
  const { setActiveDashboard, activeDashboard } = useContext(UserContext);
  const { setClientList } = useContext(ClientContext);

  const [filteredDashboards, setFilteredDashboards] = useState<any>(
    [] as getAllDashboardsResponse[]
  );
  const [dashboardUsersActive, setDashboardUsersActive] =
    useState<boolean>(false);

  useEffect(() => {
    setActiveService("dashboard");
  }, []);

  useEffect(() => {
    setFilteredDashboards(allDashboards_v2);
  }, [allDashboards_v2]);

  const selectedDashboardHandler = (dashboard: getAllDashboardsV2Response) => {
    setDashboardUsersActive(true);
    setSelectedDashboard_v2(dashboard);
    setActiveDashboard(dashboard.DashboardId);
    setFilteredDashboards(allDashboards_v2);
  };

  useEffect(() => {
    if (dashboardClients.length > 0) {
      setClientList([
        {
          ClientCode: dashboardClients[0].Client,
          ClientCountry: dashboardClients[0].Country,
        },
      ]);
    }
  }, [dashboardClients]);

  return (
    <Fragment>
      {!dashboardUsersActive && (
        <div className={classes.main__content}>
          <h1>Dashboards</h1>
          <DashboardSearch
            allDashboards_v2={allDashboards_v2}
            searchInput={searchInput}
            setFilteredDashboards={setFilteredDashboards}
          />
          <br></br>
          <br></br>
          <DashboardList
            allDashboards_v2={filteredDashboards}
            selectedDashboardHandler={selectedDashboardHandler}
          ></DashboardList>
        </div>
      )}
      {dashboardUsersActive && (
        <div>
          <DashboardUsers
            dashboardClients={dashboardClients}
            activeDashboard={activeDashboard}
            setDashboardUsersActive={setDashboardUsersActive}
          />
        </div>
      )}
    </Fragment>
  );
};

export default DashboardModule;
