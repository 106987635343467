import { Fragment, useContext, useEffect, useState } from "react";
import ResourceContext from "../../Context/resource-context";
import classes from "./Dashboard.module.css";
import AccessContext from "../../Context/access-context";
import {
  Action,
  AddAccessStatementMutation,
  AddGroupStatementMutation,
  Country,
  Environment,
  RemoveAccessStatementMutation,
  Service,
  UserType,
} from "../../API";
import {
  addAccessStatement,
  addGroupStatement,
  removeAccessStatement,
} from "../../graphql/mutations";
import UserContext from "../../Context/user-context";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const Dashboard = (props: any) => {
  const { userType } = useContext(UserContext);
  const { selectedDashboard, dashboardClients } = useContext(ResourceContext);
  const { userAccess, currentUserAccess } = useContext(AccessContext);

  const environments = ["PRODUCTION", "STAGING", "DEMO"];
  const accessTypes = [
    { has: "Access", give: "Paddington" },
    { has: "Paddington", give: "Grant" },
    { has: "Grant", give: "Grant" },
  ];
  const [response, setResponse] = useState<string[]>([] as string[]);

  const [clientList, setClientList] = useState<any>(
    [] as { Client: string; Country: string; Access: boolean }[]
  );

  const environmentHandler = (env: string) => {
    props.setActiveEnvironment(Environment[env.toUpperCase() as keyof typeof Environment]);
    props.setUserAccessRequest((prevState: any) => {
      return {
        ...prevState,
        Environment: env,
      };
    });
  };

  const accessTypeHandler = (at: { has: string; give: string }) => {
    props.setActiveAccessType(at);
    props.setUserAccessRequest((prevState: any) => {
      return {
        ...prevState,
        StatementType: at,
      };
    });
  };

  const accessHandler = () => {
    let accessList = [] as any[];

    dashboardClients.forEach((client: any) => {
      let clientFound = false;
      userAccess.Statements?.forEach((statement: any) => {
        if (
          client.Client === statement.ClientCode &&
          client.Country === statement.ClientCountry
          && statement.Resources[0].Actions.includes('READ')
        ) {
          clientFound = true;
        }
      });

      let cu_clientFound = false;
      currentUserAccess.Statements?.forEach((cu_statement: any) => {
        if (
          client.Client === cu_statement.ClientCode &&
          client.Country === cu_statement.ClientCountry
          && cu_statement.Resources[0].Actions.includes('READ')
        ) {
          cu_clientFound = true;
        }
      });

      if (clientFound && cu_clientFound) {
        accessList.push({
          Client: client.Client,
          Country: client.Country,
          Access: true,
          GiveAccess: true,
        });
      } else if (clientFound && !cu_clientFound) {
        accessList.push({
          Client: client.Client,
          Country: client.Country,
          Access: true,
          GiveAccess: false,
        });
      } else if (!clientFound && cu_clientFound) {
        accessList.push({
          Client: client.Client,
          Country: client.Country,
          Access: false,
          GiveAccess: true,
        });
      } else {
        accessList.push({
          Client: client.Client,
          Country: client.Country,
          Access: false,
          GiveAccess: false,
        });
      }
    });
    setClientList(accessList);
  };

  useEffect(() => {
    accessHandler();
  }, [dashboardClients, userAccess, currentUserAccess]);

  const checkedHandler = (client: any) => {
    const index_item = clientList.filter(
      (item: any) =>
        item.Client === client.Client && item.Country == client.Country
    )[0];
    if (index_item.Access) {
      index_item.Access = false;
      removeAccess(index_item.Client, index_item.Country);
    } else {
      index_item.Access = true;
      if (userType === UserType.GROUP) {
        addGroupAccess(index_item.Client, index_item.Country);
      } else {
        addAccess(index_item.Client, index_item.Country);
      }
    }
    setClientList((prevState: any) => {
      return [...prevState];
    });
  };

  useEffect(() => {
    console.log("This is user", userType);
    console.log("this is username", props.username);
  }, [userType]);

  async function addAccess(clientCode: string, clientCountry: string) {
    try {
      const response = (await clientAuth.graphql({
        query: addAccessStatement,
        variables: {
          Action: Action.READ,
          Environment: props.activeEnvironment,
          Service: Service.Dashboards,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: props.selectedDashboard_v2.DashboardId,
          Username: props.username,
          StatementType: props.activeAccessType.has,
        },
      })) as { data: AddAccessStatementMutation };
      console.log(response);
      if (
        response.data.addAccessStatement?.StatusCode !== 200 ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to grant access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to grant access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  async function addGroupAccess(clientCode: string, clientCountry: string) {
    try {
      const response = (await clientAuth.graphql({
        query: addGroupStatement,
        variables: {
          Action: Action.READ,
          Environment: props.activeEnvironment,
          Service: Service.Dashboards,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: props.selectedDashboard_v2.DashboardId,
          StatementType: props.activeAccessType.has,
          Group: props.username,
        },
  
      })) as { data: AddGroupStatementMutation };
      console.log(response);
      if (
        response.data.addGroupStatement?.StatusCode !== 200 ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to grant access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to grant access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  async function removeAccess(clientCode: string, clientCountry: string) {
    const variables2 = {
      Action: Action.READ,
      Environment: props.activeEnvironment,
      Service: "Dashboards",
      ClientCode: clientCode,
      ClientCountry: clientCountry,
      Resource: props.selectedDashboard_v2.DashboardId,
      Username: props.username,
      StatementType: props.activeAccessType.has,
    };
    console.log(variables2);
    try {
      const response = (await clientAuth.graphql({
        query: removeAccessStatement,
        variables: {
          Action: Action.READ,
          Environment: props.activeEnvironment,
          Service: Service.Dashboards,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: props.selectedDashboard_v2.DashboardId,
          Username: props.username,
          StatementType: props.activeAccessType.has,
        }
      })) as { data: RemoveAccessStatementMutation };
      console.log(response);
      if (
        response.data.removeAccessStatement?.StatusCode !== "200" ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to remove access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to remove access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  useEffect(() => {
    console.log(response);
  }, [response]);

  return (
    <Fragment>
      <h2>{`${props.selectedDashboard_v2.Service} - ${props.selectedDashboard_v2.Resource}`}</h2>
      <div>
        <h4>Environments</h4>
        {environments.map((env: string) => (
          <div
            onClick={() => {
              environmentHandler(env);
            }}
            className={
              env == props.activeEnvironment
                ? `${classes.selected__env__item}`
                : `${classes.env__item}`
            }
            key={env}
          >
            <p>{env}</p>
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div>
        <h4>Access Type</h4>
        {accessTypes.map((at: { has: string; give: string }) => (
          <div
            onClick={() => {
              accessTypeHandler(at);
            }}
            className={
              at.has == props.activeAccessType.has
                ? `${classes.selected__env__item}`
                : `${classes.env__item}`
            }
            key={at.has}
          >
            <p>{at.has}</p>
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <h4>Clients</h4>
      {response.map((r: string) => (
        <p style={{ color: "red" }} key={r}>
          {r}
        </p>
      ))}
      {clientList.map((client: any) => (
        <div
          className={
            !client.GiveAccess && userType !== UserType.GROUP
              ? `${classes.dashboard__item__inactive}`
              : `${classes.dashboard__item}`
          }
          key={`${client.Client} - ${client.Country}`}
        >
          <p>{`${client.Client} - ${client.Country}`}</p>

          <div className={classes.checkbox}>
            <label>Access</label>
            {userType !== UserType.GROUP && (
              <div className={classes.input__div}>
                {client.Access && client.GiveAccess && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                    checked={true}
                  />
                )}
                {client.Access && !client.GiveAccess && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                    checked={true}
                    disabled
                  />
                )}
                {!client.Access && !client.GiveAccess && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                    disabled
                  />
                )}
                {!client.Access && client.GiveAccess && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                  />
                )}
              </div>
            )}
            {userType === UserType.GROUP && (
              <div className={classes.input__div}>
                {client.Access && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                    checked={true}
                  />
                )}
                {!client.Access && (
                  <input
                    onChange={() => checkedHandler(client)}
                    type="checkbox"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default Dashboard;
