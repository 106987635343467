import { useRef } from "react";

const Dropdown = (props: any) => {
  const selectedInput: any = useRef();

  const onChangeHandler = () => {
    if (props.type === "Organization") {
      const newOrganization = props.data.find((obj: any) => {
        return obj.Organization === selectedInput.current.value;
      });
      props.onChange(newOrganization);
    } else {
      props.onChange(selectedInput.current.value);
    }
  };

  const dropdownHandler = (d: any) => {
    if (props.type === "Organization") {
      return (
        <option key={Math.random()} value={d.Organization}>
          {d.Organization}
        </option>
      );
    } else {
      return (
        <option key={Math.random()} value={d}>
          {d}
        </option>
      );
    }
  };
  return (
    <select
      ref={selectedInput as any}
      onChange={onChangeHandler}
      value={props.value}
      className={props.classes}
    >
      <option value="" disabled selected hidden>
        {props.label}
      </option>
      {props.data.map((d: string) => dropdownHandler(d))}
    </select>
  );
};

export default Dropdown;
