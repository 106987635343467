import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { getAllDashboardsResponse, UserInfo, UserType } from "../../API";
import classes from "./Users.module.css";
import UserContext from "../../Context/user-context";
import { SelectedUser } from "../../Custom-Types";
import User from "./User";
import ResourceContext from "../../Context/resource-context";
import AddNewUser from "./AddNewUser";

const Users = () => {
  const searchInput: any = useRef();

  const {
    setPaddingtonUsersRequest,
    paddingtonUsers,
    paddingtonUsersRequest,
    selectedUser,
    setSelectedUser,
  } = useContext(UserContext);

  const { setSelectedDashboard } = useContext(ResourceContext);

  const [userTypeActive, setUserTypeActive] = useState<string>("");

  const [newUserActive, setNewUserActive] = useState<boolean>(false);

  const usersRequestHandler = (userType: UserType, nextToken: string) => {
    if (nextToken == "CLEAR") {
      setPaddingtonUsersRequest({
        UserType: userType,
        nextToken: nextToken,
        Username: "",
      });
    } else {
      setPaddingtonUsersRequest({
        UserType: userType,
        nextToken: nextToken,
        Username: "",
      });
    }
    setUserTypeActive(userType);
  };

  const loadMoreUsers = () => {
    setPaddingtonUsersRequest({
      UserType: paddingtonUsersRequest.UserType,
      nextToken: paddingtonUsers.NextToken,
      Username: "",
    });
  };

  const searchHandler = () => {
    if (searchInput.current.value.trim() != "") {
      setPaddingtonUsersRequest({
        UserType: paddingtonUsersRequest.UserType,
        nextToken: "CLEAR",
        Username: searchInput.current.value.toLowerCase(),
      });
    }
  };

  const userSelectionHandler = (user: UserInfo | null | undefined) => {
    setSelectedDashboard({} as getAllDashboardsResponse);
    setSelectedUser({
      User: user,
      Active: true,
    });
  };

  const newUserHandler = () => {
    setNewUserActive(true);
  };

  useEffect(() => {
    console.log("this is paddington users", paddingtonUsers);
  }, []);

  return (
    <Fragment>
      {!selectedUser.Active && (
        <div className={classes.page__layout}>
          <h1>Users</h1>

          <div>
            <button
              className={
                userTypeActive == UserType.INTERNAL
                  ? `${classes.active__userType}`
                  : `{}`
              }
              onClick={() => usersRequestHandler(UserType.INTERNAL, "CLEAR")}
            >
              Internal
            </button>
            <button
              className={
                userTypeActive == UserType.EXTERNAL
                  ? `${classes.active__userType}`
                  : `{}`
              }
              onClick={() => usersRequestHandler(UserType.EXTERNAL, "CLEAR")}
            >
              External
            </button>
            <button
              className={
                userTypeActive == UserType.PROGRAMMATIC
                  ? `${classes.active__userType}`
                  : `{}`
              }
              onClick={() =>
                usersRequestHandler(UserType.PROGRAMMATIC, "CLEAR")
              }
            >
              Programmatic
            </button>
            <button
              className={
                userTypeActive == UserType.TEST
                  ? `${classes.active__userType}`
                  : `{}`
              }
              onClick={() => usersRequestHandler(UserType.TEST, "CLEAR")}
            >
              Test
            </button>
          </div>
          {userTypeActive && (
            <div>
              <input
                className={classes.search__option}
                placeholder="Search"
                ref={searchInput}
                type="text"
              ></input>
              <button onClick={searchHandler}>search</button>
            </div>
          )}

          {newUserActive && (
            <div>
              <AddNewUser setNewUserActive={setNewUserActive} />
            </div>
          )}

          <div>
            <div>
              <button onClick={newUserHandler}>+ New User</button>
            </div>
            {paddingtonUsers.Users?.map((users: (UserInfo | null)[]) => (
              <div key={`user batch ${users[0]?.UserName}`}>
                {users.map((user: UserInfo | null) => (
                  <div
                    onClick={() => userSelectionHandler(user)}
                    key={user?.UserName}
                    className={classes.user__item}
                  >
                    <p
                      style={{ fontSize: "17px" }}
                    >{`${user?.Name} ${user?.FamilyName}`}</p>
                    <p style={{ fontSize: "14px" }}>
                      <b>username: </b>
                      {`${user?.UserName}`}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {paddingtonUsers.NextToken !== "" && (
            <button onClick={loadMoreUsers}>Load more</button>
          )}
        </div>
      )}
      {selectedUser.Active && (
        <User activeUser={selectedUser} setSelectedUser={setSelectedUser} />
      )}
    </Fragment>
  );
};

export default Users;
