import { Fragment, useContext, useEffect, useState } from "react"
import classes from "./OMGPortalAction.module.css"
import PortalAccessContext from "../../../Context/portal-access-context"
import { addAccessStatement, addGroupStatement, removeAccessStatement } from "../../../graphql/mutations";
import ClientContext from "../../../Context/client-context";
import { AddAccessStatementMutation, AddGroupStatementMutation, RemoveAccessStatementMutation, UserType } from "../../../API";
import UserContext from "../../../Context/user-context";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const OMGPortalAction = (props: any) => {

    const {userPortalAccess, userPortalGrantableAccess} = useContext(PortalAccessContext);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [giveAccess, setGiveAccess] = useState<boolean>(false);
    const { userType } = useContext(UserContext);

    const {
        activeEnvironment,
        activeAccessType

    } = useContext(ClientContext);


    useEffect(()=>{
        if(userPortalAccess.length > 0){
            const clientIndex = userPortalAccess.findIndex((client) => client.Client === props.client);
            
            if (userPortalAccess[clientIndex]?.Access.length > 0){
                const serviceIndex = userPortalAccess[clientIndex].Access.findIndex((service) => service.Service?.trim() === props.service.trim());
                if (serviceIndex > -1){
                    const resource = userPortalAccess[clientIndex].Access[serviceIndex].Resources?.find((resource) => resource?.Resource === props.resource);

                    if (resource?.Actions?.includes(props.action)) {
                        setHasAccess(true)
                    }
                }
            }

        }
    },[userPortalAccess])

    useEffect(()=>{
      if(userPortalGrantableAccess.length > 0){
          const clientIndex = userPortalGrantableAccess.findIndex((client) => client.Client === props.client);
          
          if (userPortalGrantableAccess[clientIndex]?.Access.length > 0){
              const serviceIndex = userPortalGrantableAccess[clientIndex].Access.findIndex((service) => service.Service?.trim() === props.service.trim());
              if (serviceIndex > -1){
                  const resource = userPortalGrantableAccess[clientIndex].Access[serviceIndex].Resources?.find((resource) => resource?.Resource === props.resource);

                  if (resource?.Actions?.includes(props.action)) {
                    setGiveAccess(true)
                  }
              }
          }

      }
  },[userPortalGrantableAccess])

    const inputOnChangeHandler = (access: boolean) => {
        if (access){
          if (userType === UserType.GROUP) {
            addGroupAccess();
          } else {
            addAccess();
          }
        } else {
            removeAccess();
        }
        setHasAccess(access);
    }

    async function addGroupAccess() {
        console.log( {
            Action: props.action,
            Environment: activeEnvironment,
            Service: props.service,
            ClientCode: props.client,
            ClientCountry: props.country,
            Resource: props.resource,
            StatementType: activeAccessType.has,
            Group: props.username,
          })
        try {
          const response = (await clientAuth.graphql({
            query: addGroupStatement,
            variables: {
              Action: props.action,
              Environment: activeEnvironment,
              Service: props.service.trim(),
              ClientCode: props.client,
              ClientCountry: props.country,
              Resource: props.resource,
              StatementType: activeAccessType.has,
              Group: props.username,
            },
          })) as { data: AddGroupStatementMutation };
          console.log(response);
          if (
            response.data.addGroupStatement?.StatusCode !== 200 ||
            response.hasOwnProperty("errors")
          ) {
            props.setResponse((prevState: string[]) => {
              return [
                ...prevState,
                `Failed to grant access to ${props.client}-${props.country}`,
              ];
            });
          }
        } catch (e) {
          console.log(e);
          props.setResponse((prevState: string[]) => {
            return [
              ...prevState,
              `Failed to grant access to ${props.client}-${props.country}`,
            ];
          });
        }
      }

      async function addAccess(){
        try {
          const response = (await clientAuth.graphql({
            query: addAccessStatement,
            variables: {
              Action: props.action,
              Environment: activeEnvironment,
              Service: props.service.trim(),
              ClientCode: props.client,
              ClientCountry: props.country,
              Resource: props.resource,
              Username: props.username,
              StatementType: activeAccessType.has,
            },
          })) as {data: AddAccessStatementMutation};
          console.log(response)
          if (
            response.data.addAccessStatement?.StatusCode !== 200 ||
            response.hasOwnProperty("errors")
          ) {
            props.setResponse((prevState: string[]) => {
              return [
                ...prevState,
                `Failed to grant access to ${props.client}-${props.country}`,
              ];
            });
          }
        }catch(e){
          console.log(e);
          props.setResponse((prevState: string[]) => {
            return [
              ...prevState,
              `Failed to grant access to ${props.client}-${props.country}`,
            ];
          });
      }
    }

      async function removeAccess() {
        try {
          const response = (await clientAuth.graphql({
            query: removeAccessStatement,
            variables: {
              Action: props.action,
              Environment: activeEnvironment,
              Service: props.service.trim(),
              ClientCode: props.client,
              ClientCountry: props.country,
              Resource: props.resource,
              Username: props.username,
              StatementType: activeAccessType.has,
            },
          })) as { data: RemoveAccessStatementMutation };
          console.log(response);
          if (
            response.data.removeAccessStatement?.StatusCode !== "200" ||
            response.hasOwnProperty("errors")
          ) {
            props.setResponse((prevState: string[]) => {
              return [
                ...prevState,
                `Failed to remove access to ${props.client}-${props.country}`,
              ];
            });
          }
        } catch (e) {
          console.log(e);
          props.setResponse((prevState: string[]) => {
            return [
              ...prevState,
              `Failed to remove access to ${props.client}-${props.country}`,
            ];
          });
        }
      }
    

    return <Fragment>
        <div className={classes.actions}>
            <p className={classes.action}>{props.action}</p>
            {userType == UserType.GROUP && <div>
              {hasAccess && <input onChange={() => inputOnChangeHandler(false)} type="checkbox" checked />}
              {!hasAccess  && <input onChange={() => inputOnChangeHandler(true)} type="checkbox"/>}
              </div>}
            {userType != UserType.GROUP && <div>
              {hasAccess && giveAccess && <input onChange={() => inputOnChangeHandler(false)} type="checkbox" checked />}
              {hasAccess && !giveAccess && <input onChange={() => inputOnChangeHandler(false)} type="checkbox" checked disabled/>}
              {!hasAccess && giveAccess && <input onChange={() => inputOnChangeHandler(true)} type="checkbox"/>}
              {!hasAccess && !giveAccess && <input onChange={() => inputOnChangeHandler(true)} type="checkbox" disabled/>}
              </div>}
        </div>
    </Fragment>
}
export default OMGPortalAction