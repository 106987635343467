import { Fragment, useContext, useState } from "react";
import classes from "./Group.module.css";
import GroupList from "./GroupList";
import AddNewGroup from "./AddNewGroup";
import UserContext from "../../Context/user-context";
import AccessContext from "../../Context/access-context";

const Group = (props: any) => {
  const { groupAccessActive, setGroupAccessActive } = useContext(AccessContext);
  const [newGroupActive, setNewGroupActive] = useState<boolean>(false);

  const { setNewGroup } = useContext(UserContext);

  const newGroupActiveHandler = () => {
    setNewGroupActive(true);
    setNewGroup(false);
  };

  return (
    <Fragment>
      <h1 className={classes.header}>Groups</h1>
      {!groupAccessActive.Active && (
        <button
          className={classes.add__member_button}
          onClick={newGroupActiveHandler}
        >
          + New Group
        </button>
      )}
      {newGroupActive && (
        <AddNewGroup
          setNewGroupActive={setNewGroupActive}
          setNewGroup={setNewGroup}
        />
      )}
      <GroupList
        groupAccessActive={groupAccessActive}
        setGroupAccessActive={setGroupAccessActive}
      />
    </Fragment>
  );
};
export default Group;
