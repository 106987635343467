import { Fragment, useContext, useState } from "react";
import classes from "./User.module.css";
import Dashboards from "../Dashboards/Dashboards";
import EditUser from "./EditUser";
import ResourceContext from "../../Context/resource-context";
import {
  getAllDashboardsResponse,
  getAllDashboardsV2Response,
  updateExternalUserResponse,
} from "../../API";
import CM from "../CampaignMapping/CM";
import { CiEdit } from "react-icons/ci";
import OMGPortal from "../Portal/OMG/OMGPortal";

const User = (props: any) => {
  const [editUserActive, setEditUserActive] = useState<boolean>(false);
  const [updateResponse, setUpdateResponse] = useState<
    updateExternalUserResponse | undefined | null
  >({} as updateExternalUserResponse);
  const {
    activeService,
    setActiveService,
    setSelectedDashboard,
    setSelectedDashboard_v2,
  } = useContext(ResourceContext);

  const serviceHandler = (service: string) => {
    setActiveService(service);
    if (service === "dashboard") {
      setSelectedDashboard_v2({} as getAllDashboardsV2Response);
    }
  };

  const editUserHandler = () => {
    setEditUserActive(true);
    setUpdateResponse({} as updateExternalUserResponse);
  };

  const backHandler = () => {
    setActiveService("");
    props.setSelectedUser((prevState: any) => {
      return {
        ...prevState,
        Active: false,
      };
    });
  };
  return (
    <Fragment>
      <div className={classes.page__layout}>
        <h1>{`${props.activeUser.User.Name} ${props.activeUser.User.FamilyName}`}</h1>
        {editUserActive && (
          <div>
            <EditUser
              setEditUserActive={setEditUserActive}
              username={props.activeUser.User.UserName}
              updateResponse={updateResponse}
              setUpdateResponse={setUpdateResponse}
            ></EditUser>
          </div>
        )}
        {!editUserActive && (
          <div>
            <i className={classes.edit__icon} onClick={editUserHandler}>
              <CiEdit></CiEdit>
            </i>
            {updateResponse?.StatusCode &&
              updateResponse.StatusCode >= 200 &&
              updateResponse.StatusCode < 300 && (
                <p className={classes.email__success}>Invitation email send</p>
              )}
            <br></br>
            <br></br>
            <div>
              <button
                className={classes.back__button}
                onClick={backHandler}
              >{`← Back to users`}</button>
            </div>

            <div>
              <button
                className={
                  activeService === "dashboard"
                    ? `${classes.active__button}`
                    : `{}`
                }
                onClick={() => serviceHandler("dashboard")}
              >
                Dashboards
              </button>
              <button
                className={
                  activeService === "cm" ? `${classes.active__button}` : `{}`
                }
                onClick={() => serviceHandler("cm")}
              >
                Campaign Mapping
              </button>
              <button
              className={
                activeService === "OMG Portal" ? `${classes.active__button}` : `{}`
              }
              onClick={() => serviceHandler("OMG Portal")}
            >
              OMG Portal
            </button>
            </div>

            {activeService === "dashboard" && (
              <Dashboards username={props.activeUser.User.UserName} />
            )}

            {activeService === "cm" && (
              <CM username={props.activeUser.User.UserName} />
            )}
            {activeService === "OMG Portal" && <OMGPortal username={props.activeUser.User.UserName} />}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default User;
