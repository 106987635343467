import { Fragment, useContext } from "react";
import { Action, ClientCodesResponse, ResourceList, UserType } from "../../API";
import classes from "./CmResources.module.css";
import CmActions from "./CmActions";
import { UserClientAccessType } from "../../Custom-Types";
import AccessContext from "../../Context/access-context";
import UserContext from "../../Context/user-context";

const CmResources = (props: any) => {
  const { userCampaignMappingAccess, setUserCampaignMappingAccess } =
    useContext(AccessContext);

  const { userType } = useContext(UserContext);

  const inputOnChangeHandler = (
    client: ClientCodesResponse,
    resource: string,
    action: Action
  ) => {
    const accessIndex = userCampaignMappingAccess.findIndex(
      (item: UserClientAccessType) => item.Client === client.Client
    );
    const resourceIndex = userCampaignMappingAccess[
      accessIndex
    ].Access.findIndex((RItem: ResourceList) => RItem.Resource === resource);

    if (resourceIndex === -1) {
      let tempAccess = userCampaignMappingAccess;
      tempAccess[accessIndex]?.Access.push({
        Resource: resource,
        Actions: [action],
      } as ResourceList);

      const removeOld = userCampaignMappingAccess.filter(
        (oldClient: UserClientAccessType) =>
          oldClient.Client !== client.Client
      );

      const newAccess = [...removeOld, tempAccess[accessIndex]];
      setUserCampaignMappingAccess(newAccess);
      if (userType === UserType.GROUP) {
        props.addGroupAccess(client.Client, client.Country, action, resource);
      } else {
        props.addAccess(client.Client, client.Country, action, resource);
      }
    } else if (
      //// REMOVE ACCESS
      userCampaignMappingAccess[accessIndex]?.Access[
        resourceIndex
      ]?.Actions?.includes(action)
    ) {
      let tempAccess = userCampaignMappingAccess;

      const index_item: any =
        tempAccess[accessIndex]?.Access[resourceIndex]?.Actions?.indexOf(
          action
        );

      if (index_item > -1) {
        tempAccess[accessIndex]?.Access[resourceIndex]?.Actions?.splice(
          index_item,
          1
        );
      }
      const newAccess = [...tempAccess];
      setUserCampaignMappingAccess(newAccess);
      props.removeAccess(client.Client, client.Country, action, resource);
    } else {
      let tempAccess = userCampaignMappingAccess;
      const removeOld = tempAccess.filter(
        (oldClient: UserClientAccessType) =>
          oldClient.Client !== client.Client
      );

      tempAccess[accessIndex]?.Access[resourceIndex]?.Actions?.push(action);

      const newAccess = [...removeOld, tempAccess[accessIndex]];

      setUserCampaignMappingAccess(newAccess);
      if (userType === UserType.GROUP) {
        props.addGroupAccess(client.Client, client.Country, action, resource);
      } else {
        props.addAccess(client.Client, client.Country, action, resource);
      }
    }
  };

  return (
    <Fragment>
      <div className={classes.resource_container}>
        {props.resources.map((resource: string) => (
          <div key={resource} className={classes.resource__item_main}>
            <div className={classes.resource__item}>
              <p>{resource}</p>
            </div>

            <div className={classes.actions_container}>
              {props.actions.map((action: Action) => (
                <div key={action} className={classes.actions}>
                  <label>{action}</label>

                  <CmActions
                    client={props.client}
                    resource={resource}
                    action={action}
                    inputOnChangeHandler={inputOnChangeHandler}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CmResources;
