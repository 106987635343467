import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Action,
  AddAccessStatementMutation,
  AddGroupStatementMutation,
  ClientCodesResponse,
  Country,
  Environment,
  RemoveAccessStatementMutation,
  Service,
 
} from "../../API";

import classes from "./CM.module.css";
import { BsCaretRightFill, BsCaretDownFill } from "react-icons/bs";
import ClientContext from "../../Context/client-context";
import AccessContext from "../../Context/access-context";
import CmResources from "./CmResources";
import {
  addAccessStatement,
  addGroupStatement,
  removeAccessStatement,
} from "../../graphql/mutations";
import ClientDropdown from "../Dropdown/ClientDropdown";
import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const CM = (props: any) => {
  const searchInput: any = useRef();
  const {
    activeCountry,
    activeAccessType,
    activeEnvironment,
    campaignMappingClients,

  } = useContext(ClientContext);

  const { setCampaignMappingAccessRequest } = useContext(AccessContext);

  const actions: Action[] = [
    Action.READ,
    Action.CREATE,
    Action.UPDATE,
    Action.DELETE,
  ];

  const resources: string[] = ["CAMPAIGN", "CLIENT", "DATASOURCE", "TAG"];


  const [viewAll, setViewAll] = useState<string[]>([] as string[]);
  const [response, setResponse] = useState<string[]>([] as string[]);

  const [filteredCampaignMappingClients, setFilteredCampaignMappingClients] =
    useState<any>([] as ClientCodesResponse[]);


  useEffect(() => {
    setFilteredCampaignMappingClients([] as ClientCodesResponse[]);
  }, []);

  useEffect(() => {
    setFilteredCampaignMappingClients(campaignMappingClients);
    console.log(campaignMappingClients);
  }, [campaignMappingClients]);

  const searchOnChangeHandler = () => {
    let items = campaignMappingClients.filter((item: ClientCodesResponse) =>
      item.Client?.toLowerCase().includes(
        searchInput.current.value.toLowerCase()
      )
    );
    setFilteredCampaignMappingClients(items);
  };

  const expandHandler = (client: string) => {
    if (!viewAll.includes(client)) {
      setViewAll((prevState: string[]) => {
        return [...prevState, client];
      });

      setCampaignMappingAccessRequest({
        Service: "CM",
        ClientCode: client,
        ClientCountry: activeCountry,
        Environment: activeEnvironment,
        StatementType: activeAccessType,
        User: props.username,
      });
    } else {
      const arr = viewAll.filter((item: string) => item !== client);
      setViewAll(arr);
    }
  };

  async function addAccess(
    clientCode: string,
    clientCountry: string,
    action: Action,
    resource: string
  ) {
    try {
      const response = (await clientAuth.graphql({
        query: addAccessStatement,
        variables: {
          Action: action,
          Environment: activeEnvironment,
          Service: Service.CM,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: resource,
          Username: props.username,
          StatementType: activeAccessType.has,
        },
      })) as { data: AddAccessStatementMutation };
      console.log(response);
      if (
        response.data.addAccessStatement?.StatusCode !== 200 ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to grant access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to grant access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  async function addGroupAccess(
    clientCode: string,
    clientCountry: string,
    action: Action,
    resource: string
  ) {
    try {
      const response = (await clientAuth.graphql({
        query: addGroupStatement,
        variables: {
          Action: action,
          Environment: activeEnvironment,
          Service: Service.CM,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: resource,
          StatementType: activeAccessType.has,
          Group: props.username,
        },
      })) as { data: AddGroupStatementMutation };
      console.log(response);
      if (
        response.data.addGroupStatement?.StatusCode !== 200 ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to grant access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to grant access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  async function removeAccess(
    clientCode: string,
    clientCountry: string,
    action: Action,
    resource: string
  ) {
    try {
      const response = (await clientAuth.graphql({
        query: removeAccessStatement,
        variables: {
          Action: action,
          Environment: activeEnvironment,
          Service: Service.CM,
          ClientCode: clientCode,
          ClientCountry: Country[clientCountry as keyof typeof Country],
          Resource: resource,
          Username: props.username,
          StatementType: activeAccessType.has,
        },
      })) as { data: RemoveAccessStatementMutation };
      console.log(response);
      if (
        response.data.removeAccessStatement?.StatusCode !== "200" ||
        response.hasOwnProperty("errors")
      ) {
        setResponse((prevState: string[]) => {
          return [
            ...prevState,
            `Failed to remove access to ${clientCode}-${clientCountry}`,
          ];
        });
      }
    } catch (e) {
      console.log(e);
      setResponse((prevState: string[]) => {
        return [
          ...prevState,
          `Failed to remove access to ${clientCode}-${clientCountry}`,
        ];
      });
    }
  }

  return (
    <Fragment>

      <ClientDropdown setViewAll={setViewAll} />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <input
        className={classes.search__input}
        onChange={searchOnChangeHandler}
        placeholder="search"
        ref={searchInput}
        type="text"
      />

      <div className={classes.client__box_container}>
        {filteredCampaignMappingClients.map((client: any) => (
          <div
            className={classes.client__box}
            key={`${client.Client} - ${client.Country}`}
          >
            <p className={classes.client__name}>
              <b>{client.Client}</b>
            </p>

            {!viewAll.includes(client.Client) && (
              <div
                onClick={() => expandHandler(client.Client)}
                className={classes.arrow}
              >
                <BsCaretRightFill />
              </div>
            )}

            {viewAll.includes(client.Client) && (
              <div
                onClick={() => expandHandler(client.Client)}
                className={classes.arrow}
              >
                <BsCaretDownFill />
              </div>
            )}

            {viewAll.includes(client.Client) && (
              <CmResources
                resources={resources}
                client={client}
                actions={actions}
                addAccess={addAccess}
                addGroupAccess={addGroupAccess}
                removeAccess={removeAccess}
              />
            )}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CM;
