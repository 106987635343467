import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./Context/auth-context";
import { EnvironmentContextProvider } from "./Context/environment-context";
import { OrganizationContextProvider } from "./Context/organization-context";
import { UserContextProvider } from "./Context/user-context";
import { ResourceContextProvider } from "./Context/resource-context";
import { AccessContextProvider } from "./Context/access-context";
import { ClientContextProvider } from "./Context/client-context";
import { PortalAccessContextProvider } from "./Context/portal-access-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <EnvironmentContextProvider>
        <OrganizationContextProvider>
          <UserContextProvider>
            <ClientContextProvider>
              <ResourceContextProvider>
                <AccessContextProvider>
                  <PortalAccessContextProvider>
                    <App />
                  </PortalAccessContextProvider>
                </AccessContextProvider>
              </ResourceContextProvider>
            </ClientContextProvider>
          </UserContextProvider>
        </OrganizationContextProvider>
      </EnvironmentContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
