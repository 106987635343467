/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOrganizations = /* GraphQL */ `query GetOrganizations($Environment: Environment!) {
  getOrganizations(Environment: $Environment) {
    Organization
    OrganizationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationsQueryVariables,
  APITypes.GetOrganizationsQuery
>;
export const getClients = /* GraphQL */ `query GetClients($Organization_id: String!) {
  getClients(Organization_id: $Organization_id) {
    ClientCodes
    Country
    OrganizationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientsQueryVariables,
  APITypes.GetClientsQuery
>;
export const getUserAccessStatements = /* GraphQL */ `query GetUserAccessStatements(
  $Environment: Environment!
  $Organization_id: String!
  $Resource: String
  $UserType: UserType!
) {
  getUserAccessStatements(
    Environment: $Environment
    Organization_id: $Organization_id
    Resource: $Resource
    UserType: $UserType
  ) {
    Clients {
      ClientCode
      ClientCountry
      __typename
    }
    Users {
      Username
      Email
      Name
      Family_name
      Statements {
        Action
        ClientCode
        ClientCountry
        Environment
        Resource
        Service
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAccessStatementsQueryVariables,
  APITypes.GetUserAccessStatementsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($Environment: Environment!, $Organization_id: String!) {
  getUser(Environment: $Environment, Organization_id: $Organization_id) {
    Clients {
      ClientCode
      ClientCountry
      __typename
    }
    Users {
      Username
      Email
      Name
      Family_name
      Statements {
        ClientCode
        ClientCountry
        Resources
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const getDashboards = /* GraphQL */ `query GetDashboards($Organization_id: String!, $Environment: Environment!) {
  getDashboards(Organization_id: $Organization_id, Environment: $Environment) {
    ClientCode
    ClientCountry
    Resources {
      Dashboard
      Access
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardsQueryVariables,
  APITypes.GetDashboardsQuery
>;
export const listAllOrganizations = /* GraphQL */ `query ListAllOrganizations {
  listAllOrganizations {
    Organization
    OrganizationId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllOrganizationsQueryVariables,
  APITypes.ListAllOrganizationsQuery
>;
export const getGroups = /* GraphQL */ `query GetGroups {
  getGroups
}
` as GeneratedQuery<APITypes.GetGroupsQueryVariables, APITypes.GetGroupsQuery>;
export const getGroup = /* GraphQL */ `query GetGroup(
  $Group: String!
  $Environment: Environment!
  $Organization_id: String!
) {
  getGroup(
    Group: $Group
    Environment: $Environment
    Organization_id: $Organization_id
  ) {
    Clients {
      ClientCode
      ClientCountry
      __typename
    }
    Users {
      Username
      Email
      Name
      Family_name
      Statements {
        Action
        ClientCode
        ClientCountry
        Environment
        Resource
        Service
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const getGroupMembers = /* GraphQL */ `query GetGroupMembers($Group: String!) {
  getGroupMembers(Group: $Group) {
    Username
    Name
    FamilyName
    Email
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupMembersQueryVariables,
  APITypes.GetGroupMembersQuery
>;
export const getUsers = /* GraphQL */ `query GetUsers(
  $UserType: UserType!
  $nextToken: String
  $Limit: Int
  $Username: String
) {
  getUsers(
    UserType: $UserType
    nextToken: $nextToken
    Limit: $Limit
    Username: $Username
  ) {
    Users {
      Agency
      Email
      FamilyName
      Name
      UserName
      UserType
      __typename
    }
    NextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersQueryVariables, APITypes.GetUsersQuery>;
export const getAllDashboards = /* GraphQL */ `query GetAllDashboards($PK: String, $SK: String) {
  getAllDashboards(PK: $PK, SK: $SK) {
    PK
    SK
    ClientCodes {
      Client
      Country
      __typename
    }
    ClientSpecific
    DashboardRoute
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllDashboardsQueryVariables,
  APITypes.GetAllDashboardsQuery
>;
export const getAllDashboards_v2 = /* GraphQL */ `query GetAllDashboards_v2 {
  getAllDashboards_v2 {
    DashboardId
    DashboardName
    DashboardRoute
    PK
    Resource
    Service
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllDashboards_v2QueryVariables,
  APITypes.GetAllDashboards_v2Query
>;
export const getStatements = /* GraphQL */ `query GetStatements(
  $User: String
  $Environment: Environment!
  $Service: String!
  $Resource: String!
  $StatementType: StatementType!
  $FromGroup: String
) {
  getStatements(
    User: $User
    Environment: $Environment
    Service: $Service
    Resource: $Resource
    StatementType: $StatementType
    FromGroup: $FromGroup
  ) {
    User
    Statements {
      ClientCode
      ClientCountry
      Service
      Environment
      Resources {
        Resource
        Actions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatementsQueryVariables,
  APITypes.GetStatementsQuery
>;
export const getCampaignMappingClients = /* GraphQL */ `query GetCampaignMappingClients($country: Country!, $agency: String!) {
  getCampaignMappingClients(country: $country, agency: $agency) {
    Client
    Country
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignMappingClientsQueryVariables,
  APITypes.GetCampaignMappingClientsQuery
>;
export const getStatementsFromClient = /* GraphQL */ `query GetStatementsFromClient(
  $ClientCode: String!
  $ClientCountry: Country!
  $Service: String!
  $Environment: Environment!
  $StatementType: StatementType!
  $User: String
) {
  getStatementsFromClient(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Service: $Service
    Environment: $Environment
    StatementType: $StatementType
    User: $User
  ) {
    Resource
    Actions
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatementsFromClientQueryVariables,
  APITypes.GetStatementsFromClientQuery
>;
export const getCampaignMappingClientInfo = /* GraphQL */ `query GetCampaignMappingClientInfo($country: Country!, $client: String!) {
  getCampaignMappingClientInfo(country: $country, client: $client) {
    clientName
    agency
    parentCode
    marathonId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCampaignMappingClientInfoQueryVariables,
  APITypes.GetCampaignMappingClientInfoQuery
>;
export const getDashboardClients = /* GraphQL */ `query GetDashboardClients($Resource: String!, $Service: String!) {
  getDashboardClients(Resource: $Resource, Service: $Service) {
    ActualCountry
    Country
    Client
    Production
    Staging
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardClientsQueryVariables,
  APITypes.GetDashboardClientsQuery
>;
export const getDashboardAccess = /* GraphQL */ `query GetDashboardAccess(
  $Resource: String!
  $Environment: Environment!
  $StatementType: StatementType!
  $UserType: UserType!
  $Clients: [Clients]!
) {
  getDashboardAccess(
    Resource: $Resource
    Environment: $Environment
    StatementType: $StatementType
    UserType: $UserType
    Clients: $Clients
  ) {
    Action
    ClientCode
    ClientCountry
    Resource
    Service
    Username
    Email
    Name
    FamilyName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardAccessQueryVariables,
  APITypes.GetDashboardAccessQuery
>;
export const getProjectAccess = /* GraphQL */ `query GetProjectAccess($Level: AccessLevel!) {
  getProjectAccess(Level: $Level) {
    Level
    Priority
    Services {
      Service
      Resources {
        Resource
        Actions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectAccessQueryVariables,
  APITypes.GetProjectAccessQuery
>;
export const getUserAccessFromClient = /* GraphQL */ `query GetUserAccessFromClient(
  $ClientCode: String!
  $ClientCountry: Country!
  $Environment: Environment!
  $AccessType: StatementType!
  $User: String
) {
  getUserAccessFromClient(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    AccessType: $AccessType
    User: $User
  ) {
    Service
    Resources {
      Resource
      Actions
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAccessFromClientQueryVariables,
  APITypes.GetUserAccessFromClientQuery
>;
