import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Environment,
  GetGroupMembersQuery,
  GetGroupQuery,
  GetGroupsQuery,
  GetUserAccessStatementsQuery,
  GetUserQuery,
  GetUsersQuery,
  GroupMemeberInfo,
  SingleUserResponse,
  UserResponse,
} from "../API";
import {
  getGroup,
  getGroupMembers,
  getGroups,
  getUser,
  getUserAccessStatements,
  getUsers,
} from "../graphql/queries";
import EnvironmentContext from "./environment-context";
import OrganizationContext from "./organization-context";
import AuthContext from "./auth-context";
import { UserType } from "../API";
import { PaddingtonUsers, SelectedUser } from "../Custom-Types";

import { generateClient } from "aws-amplify/api";
const client = generateClient({authMode: "userPool"});

const UserContext = createContext({
  users: [] as UserResponse[],
  paddingtonUsers: {} as PaddingtonUsers,
  paddingtonUsersRequest: {} as {
    UserType: UserType;
    nextToken: string | null | undefined;
    Username: string;
  },
  activeUser: {} as UserResponse,
  authorizedUser: {} as SingleUserResponse,
  userType: UserType.NONE as UserType,
  groups: [] as string[],
  group: {} as UserResponse,
  groupMembers: [] as GroupMemeberInfo[],
  selectedGroup: "" as string,
  selectedUser: {} as SelectedUser,
  dashboardUsers: [] as UserResponse[],
  activeDashboard: "" as string | null | undefined,
  setUsers: (args: UserResponse[]) => {},
  setActiveUser: (args: UserResponse) => {},
  setUserType: (args: UserType) => {},
  setSelectedGroup: (args: string) => {},
  setNewGroupMember: (args: boolean) => {},
  setNewGroup: (args: boolean) => {},
  setPaddingtonUsersRequest: (args: {
    UserType: UserType;
    nextToken: string | null | undefined;
    Username: string;
  }) => {},
  setSelectedUser: (args: SelectedUser) => {},
  setGroup: (args: UserResponse) => {},
  setDashboardUsers: (args: UserResponse[]) => {},
  setActiveDashboard: (args: string | null | undefined) => {},
});

export const UserContextProvider = (props: any) => {
  const [users, setUsers] = useState<any>([] as UserResponse[]);
  const [dashboardUsers, setDashboardUsers] = useState<any>(
    [] as UserResponse[]
  );
  const [activeUser, setActiveUser] = useState<any>({} as UserResponse);
  const [authorizedUser, setAuthorizedUser] = useState<any>(
    {} as SingleUserResponse
  );
  const [userType, setUserType] = useState<UserType>(UserType.NONE);
  const [paddingtonUsers, setPaddingtonUsers] = useState<PaddingtonUsers>({
    Users: [],
    NextToken: "",
  } as PaddingtonUsers);
  const [paddingtonUsersRequest, setPaddingtonUsersRequest] = useState<{
    UserType: UserType;
    nextToken: string | null | undefined;
    Username: string;
  }>(
    {} as {
      UserType: UserType;
      nextToken: string | null | undefined;
      Username: string;
    }
  );

  const [activeDashboard, setActiveDashboard] = useState<
    string | null | undefined
  >("");
  const [groups, setGroups] = useState<any>([] as string[]);
  const [group, setGroup] = useState<any>({} as UserResponse);
  const [selectedGroup, setSelectedGroup] = useState("" as string);

  const [selectedUser, setSelectedUser] = useState<SelectedUser>({
    Active: false,
  } as SelectedUser);

  const [groupMembers, setGroupMembers] = useState<any>(
    [] as GroupMemeberInfo[]
  );
  const [newGroupMember, setNewGroupMember] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<boolean>(false);

  const { activeEnvironment } = useContext(EnvironmentContext);
  const { activeOrganization, newOrganizationActive } =
    useContext(OrganizationContext);
  const { user } = useContext(AuthContext);

  const fetchActiveGroup = useCallback(async () => {
    console.log("Calling fetchActiveGroup");
    try {
      const response = (await client.graphql({
        query: getGroup,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Organization_id: activeOrganization.OrganizationId as string,
          Group: selectedGroup,
        }
      })) as { data: GetGroupQuery };
      setGroup(response.data.getGroup?.Users?.at(0));
      console.log(response.data.getGroup?.Users?.at(0));
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }, [activeEnvironment, activeOrganization, selectedGroup]);

  useEffect(() => {
    if (
      activeEnvironment &&
      Object.keys(activeOrganization).length > 0 &&
      selectedGroup !== ""
    ) {
      fetchActiveGroup();
    }
  }, [activeEnvironment, activeOrganization, selectedGroup]);

  const fetchGroups = useCallback(async () => {
    console.log("Calling fetchGroups");
    try {
      const responseList = (await client.graphql({
        query: getGroups,
      })) as { data: GetGroupsQuery };
      console.log(responseList);
      setGroups(responseList.data.getGroups);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (
      //activeEnvironment &&
      //Object.keys(activeOrganization).length > 0 &&
      userType === UserType.GROUP ||
      newOrganizationActive
    ) {
      fetchGroups();
    }
  }, [
    //activeEnvironment,
    //activeOrganization,
    userType,
    newOrganizationActive,
    newGroup,
  ]);

  const fetchGroupMembers = useCallback(async () => {
    console.log("Calling fetchGroupMembers");
    try {
      const responseList = (await client.graphql({
        query: getGroupMembers,
        variables: {
          Group: selectedGroup,
        }
      })) as { data: GetGroupMembersQuery };
      console.log(responseList);
      setGroupMembers(responseList.data.getGroupMembers);
    } catch (e) {
      console.log(e);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      fetchGroupMembers();
    }
  }, [selectedGroup, fetchGroupMembers]);

  useEffect(() => {
    if (newGroupMember) {
      fetchGroupMembers();
    }
  }, [newGroupMember]);

  const fetchInternalUsers = useCallback(async () => {
    console.log("Fetch internal users called");

    try {
      const responseList = (await client.graphql({
        query: getUserAccessStatements,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Organization_id: activeOrganization.OrganizationId as string,
          UserType: UserType.INTERNAL,
          Resource: activeDashboard,
        }
      })) as { data: GetUserAccessStatementsQuery };
      setUsers(responseList.data.getUserAccessStatements?.Users);
      console.log(responseList);
    } catch (e) {
      console.log(`Failed to fetch users - ${e}`);
    }
  }, [activeEnvironment, activeOrganization, userType, activeDashboard]);

  const fetchExternalUsers = useCallback(async () => {
    console.log("Fetch external users called");
    try {
      const responseList = (await client.graphql({
        query: getUserAccessStatements,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Organization_id: activeOrganization.OrganizationId as string,
          UserType: userType,
        }
      })) as { data: GetUserAccessStatementsQuery };
      setUsers(responseList.data.getUserAccessStatements?.Users);
      console.log(responseList);
    } catch (e) {
      console.log(`Failed to fetch users - ${e}`);
    }
  }, [activeEnvironment, activeOrganization, userType]);

  useEffect(() => {
    if (
      activeEnvironment &&
      Object.keys(activeOrganization).length > 0 &&
      userType === UserType.INTERNAL
    ) {
      fetchInternalUsers();
    }
  }, [
    fetchInternalUsers,
    activeEnvironment,
    activeOrganization,
    userType,
    activeDashboard,
  ]);

  useEffect(() => {
    if (
      activeEnvironment &&
      Object.keys(activeOrganization).length > 0 &&
      [
        UserType.EXTERNAL,
        UserType.TEST,
        UserType.COGNITO,
        UserType.PROGRAMMATIC,
      ].includes(userType)
    ) {
      fetchExternalUsers();
    }
  }, [fetchExternalUsers, activeEnvironment, activeOrganization, userType]);

  const fetchAuthorizedUser = useCallback(async () => {
    console.log("Fetch user called");
    try {
      const response = (await client.graphql({
        query: getUser,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Organization_id: activeOrganization.OrganizationId as string,
        }
      })) as { data: GetUserQuery };
      setAuthorizedUser(response.data.getUser?.Users?.at(0));
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }, [activeEnvironment, activeOrganization]);

  useEffect(() => {
    if (activeEnvironment && Object.keys(activeOrganization).length > 0) {
      fetchAuthorizedUser();
    }
  }, [fetchAuthorizedUser, activeEnvironment, activeOrganization]);

  const fetchPaddingtonUsers = useCallback(async () => {
    console.log("Fetch Paddington Users Called");

    try {
      if (paddingtonUsersRequest.nextToken == "CLEAR") {
        const response = (await client.graphql({
          query: getUsers,
          variables: {
            UserType:
              UserType[
                paddingtonUsersRequest.UserType as keyof typeof UserType
              ],
            nextToken: "",
            Username: paddingtonUsersRequest.Username,
          }
        })) as { data: GetUsersQuery };
        console.log(response);
        setPaddingtonUsers({
          Users: [response.data.getUsers?.Users],
          NextToken: response.data.getUsers?.NextToken,
        });
      } else {
        const response = (await client.graphql({
          query: getUsers,
          variables: {
            UserType:
              UserType[
                paddingtonUsersRequest.UserType as keyof typeof UserType
              ],
            nextToken: paddingtonUsersRequest.nextToken,
            Username: paddingtonUsersRequest.Username,
          },
     
        })) as { data: GetUsersQuery };

        setPaddingtonUsers((prevState: any) => {
          return {
            Users: [...prevState.Users, response.data.getUsers?.Users],
            NextToken: response.data.getUsers?.NextToken,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [paddingtonUsersRequest]);

  useEffect(() => {
    if (Object.keys(paddingtonUsersRequest).length > 0) {
      fetchPaddingtonUsers();
    }
  }, [paddingtonUsersRequest, fetchPaddingtonUsers]);

  const fetchDashboardUsers = useCallback(async () => {
    console.log("Fetch Dashboard Users Called!");
    try {
      const responseList = (await client.graphql({
        query: getUserAccessStatements,
        variables: {
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Organization_id: activeOrganization.OrganizationId as string,
          UserType: userType,
          Resource: activeDashboard,
        },
 
      })) as { data: GetUserAccessStatementsQuery };

      setDashboardUsers(responseList.data.getUserAccessStatements?.Users);
      console.log(responseList);
    } catch (e) {
      console.log(`Failed to fetch dashboard users - ${e}`);
    }
  }, [activeEnvironment, activeOrganization, userType, activeDashboard]);

  useEffect(() => {
    if (
      activeEnvironment &&
      activeDashboard &&
      Object.keys(activeOrganization).length > 0 &&
      (userType === UserType.INTERNAL || userType === UserType.EXTERNAL)
    ) {
      fetchDashboardUsers();
    }
  }, [
    fetchDashboardUsers,
    activeEnvironment,
    activeOrganization,
    userType,
    activeDashboard,
  ]);

  return (
    <UserContext.Provider
      value={{
        users,
        paddingtonUsers,
        paddingtonUsersRequest,
        activeUser,
        setUsers,
        setActiveUser,
        authorizedUser,
        setUserType,
        userType,
        groups,
        group,
        groupMembers,
        dashboardUsers,
        activeDashboard,
        setSelectedGroup,
        selectedGroup,
        setNewGroupMember,
        setNewGroup,
        setPaddingtonUsersRequest,
        selectedUser,
        setSelectedUser,
        setGroup,
        setDashboardUsers,
        setActiveDashboard,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
