import { Fragment, useContext, useRef, useState } from "react";
import Card from "../UI/Card";
import classes from "./AddNewUser.module.css";
import OrganizationContext from "../../Context/organization-context";
import {
  AddInternalUserMutation,
  getOrganizationsResponse,
  AddExternalUserMutation,
  CognitoResponseType,
  UserType,
  Environment,
} from "../../API";
import { addInternalUser, addExternalUser } from "../../graphql/mutations";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const AddNewUser = (props: any) => {
  const nameInput: any = useRef();
  const lastNameInput: any = useRef();
  const emailInput: any = useRef();
  const organizationInput: any = useRef();

  const [userType, setUserType] = useState("" as string);

  const { allOrganizations } = useContext(OrganizationContext);

  const cancelHandler = () => {
    props.setNewUserActive(false);
  };

  const userTyeHandler = (type: string) => {
    setUserType(type);
  };

  const backdrop = () => {
    return <div className={classes.backdrop} onClick={cancelHandler}></div>;
  };

  const modalOverlay = () => {
    return (
      <Card className={classes.modal}>
        <div className={classes.modal__layout}>
          <h1>New User</h1>
          <form onSubmit={submitHandler}>
            <div className={classes.input__layout}>
              <input ref={nameInput} required placeholder="First Name" />
              <input ref={lastNameInput} required placeholder="Family Name" />
              <input ref={emailInput} required placeholder="Email" />
            </div>
            <div className={classes.radio__layout}>
              <div>
                <input
                  name="fav_language"
                  type="radio"
                  id="lol"
                  onClick={() => userTyeHandler(UserType.INTERNAL)}
                ></input>
                <label htmlFor="lol">Internal</label>
              </div>
              <div>
                <input
                  name="fav_language"
                  type="radio"
                  id="lol4"
                  onClick={() => userTyeHandler(UserType.EXTERNAL)}
                ></input>
                <label htmlFor="lol4">External</label>
              </div>
            </div>
            {userType == UserType.EXTERNAL && (
              <div style={{ marginBottom: "20px" }}>
                <label>Choose Organization</label>
                <br></br>
                <select
                  /* className={
                        invalidInputs.clientOrganizationInputError
                          ? classes.countryDropDownError
                          : ""
                      } */
                  ref={organizationInput}
                >
                  <option value="" disabled selected hidden>
                    Organizations
                  </option>
                  {allOrganizations.map((item: any) => (
                    <option key={`${item.OrganizationId}-${item.Organization}`}>
                      {item.Organization}
                    </option>
                  ))}
                  <option>Telenor</option>
                </select>
              </div>
            )}
            <div className={classes.button__layout}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </Card>
    );
  };

  const submitHandler = (event: any) => {
    event.preventDefault();

    if (userType == UserType.EXTERNAL) {
      const organization = allOrganizations.find((obj: any) => {
        return obj.Organization === organizationInput.current.value;
      });
      saveExternalUser(organization);
    } else {
      saveInternalUser();
    }

    console.log(nameInput.current.value);
    console.log(lastNameInput.current.value);
    console.log(emailInput.current.value);
    console.log(userType);

    nameInput.current.value = "";
    lastNameInput.current.value = "";
    emailInput.current.value = "";
  };

  async function saveInternalUser() {
    console.log(emailInput.current.value);
    try {
      props.setNewUserActive(false);
      const response = (await clientAuth.graphql({
        query: addInternalUser,
        variables: {
          Email: emailInput.current.value,
          Environment: Environment.PRODUCTION,
        },
      })) as { data: AddInternalUserMutation };
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  async function saveExternalUser(
    organization_id: getOrganizationsResponse | undefined
  ) {
    try {
      props.setNewUserActive(false);
      const response = (await clientAuth.graphql({
        query: addExternalUser,
        variables: {
          Email: emailInput.current.value,
          Name: nameInput.current.value,
          Family_name: lastNameInput.current.value,
          Organization_id: organization_id?.OrganizationId as string,
          Environment: Environment.PRODUCTION,
          Agency: "ANNALECT",
          WelcomeUrl: "https://nordics.annalect.com/",
        },
      })) as { data: AddExternalUserMutation };
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Fragment>
      {backdrop()},{modalOverlay()}
    </Fragment>
  );
};

export default AddNewUser;
