import { Fragment } from "react";
import { getAllDashboardsV2Response } from "../../API";
import classes from "./Dashboards.module.css";

const DashboardList = (props: any) => {
  return (
    <Fragment>
      {props.allDashboards_v2.map((dashboard: getAllDashboardsV2Response) => (
        <div
          className={classes.dashboard__item}
          key={`${dashboard.PK}`}
          onClick={() => props.selectedDashboardHandler(dashboard)}
        >
          <p>
            <b>{dashboard.Service}</b>
          </p>
          <p className={classes.dashboard__item__resource}>
            <b>{dashboard.Resource}</b>
          </p>
          <p>{dashboard.DashboardRoute}</p>
        </div>
      ))}
    </Fragment>
  );
};

export default DashboardList;
