import { Fragment, useContext, useEffect, useRef, useState } from "react"
import ClientDropdown from "../../Dropdown/ClientDropdown"
import ClientContext from "../../../Context/client-context";
import PortalAccessContext from "../../../Context/portal-access-context";
import { BsCaretRightFill } from "react-icons/bs";
import classes from "./OMGPortal.module.css"
import OMGPortalAccess from "./OMGPortalAccess";
import { ClientCodesResponse } from "../../../API";

const OMGPortal = (props: any) => {

    const searchInput: any = useRef();

    const {
        campaignMappingClients,
        activeCountry,
        activeEnvironment,
        activeAccessType

    } = useContext(ClientContext);

    const {setPortalAccessRequest, userPortalAccess} = useContext(PortalAccessContext);

    const [response, setResponse] = useState<string[]>([] as string[]);
    const [viewAll, setViewAll] = useState<string[]>([] as string[]);
    const [filteredCampaignMappingClients, setFilteredCampaignMappingClients] =
    useState<ClientCodesResponse[]>([] as ClientCodesResponse[]);

    const clientSelectHandler = (client: string) => {
        console.log(activeAccessType);
        console.log(activeCountry);
        console.log(activeEnvironment)

        setPortalAccessRequest({
            ClientCode: client,
            ClientCountry: activeCountry,
            Environment: activeEnvironment,
            StatementType: activeAccessType,
            User: props.username
        })

        setViewAll((prev: string[]) => {
            if (prev.includes(client)) {
                return prev.filter((item) => item !== client);
            } else {
                return [...prev, client];
            }
        });
    }

    useEffect(()=>{
        console.log(userPortalAccess)
    },[userPortalAccess])

    useEffect(() => {
        setFilteredCampaignMappingClients([] as ClientCodesResponse[]);
      }, []);
    
    useEffect(() => {
    setFilteredCampaignMappingClients(campaignMappingClients);
    console.log(campaignMappingClients);
    }, [campaignMappingClients]);

    const searchOnChangeHandler = () => {
        let items = campaignMappingClients.filter((item: ClientCodesResponse) =>
          item.Client?.toLowerCase().includes(
            searchInput.current.value.toLowerCase()
          )
        );
        setFilteredCampaignMappingClients(items);
      };

    return <Fragment>
        <ClientDropdown setViewAll={setViewAll} />

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <input
            className={classes.search__input}
            onChange={searchOnChangeHandler}
            placeholder="search"
            ref={searchInput}
            type="text"
        />

        <div className={classes.client__box_container}>
            {filteredCampaignMappingClients.map((client: any) => (
                <div key={`${client.Client} - ${client.Country}`} className={classes.client__box}>
                    <div className={classes.client__box_wrap}>
                        <p className={classes.client__name}>
                            <b>{client.Client}</b>
                        </p>

                        {!viewAll.includes(client.Client) && (
                            <div
                                className={classes.arrow}
                                onClick={() => clientSelectHandler(client.Client)}
                            >
                                <BsCaretRightFill />
                            </div>
                        )}
                        { viewAll.includes(client.Client) && <div>
                            <OMGPortalAccess client={client.Client} country={client.Country} response={response} setResponse={setResponse} username={props.username} />
                            <div
                                className={classes.arrow}
                                onClick={() => clientSelectHandler(client.Client)}
                            >
                                <BsCaretRightFill />
                            </div>
                        </div>}

                    </div>
                </div>
            ))}
        </div>

    </Fragment>
}

export default OMGPortal