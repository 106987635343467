import { Fragment, useContext, useEffect, useRef, useState } from "react";
import classes from "./AddGroupMember.module.css";
import Card from "../UI/Card";
import ReactDOM from "react-dom";
import {
  addExternalUser,
  addInternalUser,
  addUserToGroup,
} from "../../graphql/mutations";
import {
  AddExternalUserMutation,
  AddInternalUserMutation,
  AddUserToGroupMutation,
  CognitoResponseType,
  Environment,
  getOrganizationsResponse,
} from "../../API";
import OrganizationContext from "../../Context/organization-context";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const AddGroupMember = (props: any) => {
  const nameInput: any = useRef();
  const familyNameInput: any = useRef();
  const emailInput: any = useRef();
  const organizationInput: any = useRef();

  const [userType, setUserType] = useState("" as string);
  const [responseStatus, setResponseStatus] = useState<any>(
    {} as CognitoResponseType
  );

  const { allOrganizations } = useContext(OrganizationContext);

  const userTyeHandler = (type: string) => {
    setUserType(type);
  };

  const cancelHandler = () => {
    props.setAddMemberActive(false);
  };

  const backdrop = () => {
    return <div className={classes.backdrop} onClick={cancelHandler}></div>;
  };

  const modalOverlay = () => {
    return (
      <Card className={classes.modal}>
        <div className={classes.modal__layout}>
          <header>
            <h1>New Member</h1>
          </header>
          <div>
            <form onSubmit={onSubmitHandler}>
              <div className={classes.input__layout}>
                <input required ref={nameInput} placeholder="First Name" />
                <input
                  required
                  ref={familyNameInput}
                  placeholder="Family Name"
                />
                <input required ref={emailInput} placeholder="Email" />

                <div className={classes.radio__layout}>
                  <div>
                    <input
                      name="fav_language"
                      type="radio"
                      id="lol"
                      onClick={() => userTyeHandler("internal")}
                    ></input>
                    <label htmlFor="lol">Internal</label>
                  </div>
                  <div>
                    <input
                      name="fav_language"
                      type="radio"
                      id="lol4"
                      onClick={() => userTyeHandler("external")}
                    ></input>
                    <label htmlFor="lol4">External</label>
                  </div>
                </div>
                {userType == "external" && (
                  <div style={{ marginBottom: "20px" }}>
                    <label>Choose Organization</label>
                    <br></br>
                    <select
                      /* className={
                        invalidInputs.clientOrganizationInputError
                          ? classes.countryDropDownError
                          : ""
                      } */
                      ref={organizationInput}
                    >
                      <option value="" disabled selected hidden>
                        Organizations
                      </option>
                      {allOrganizations.map((item: any) => (
                        <option
                          key={`${item.OrganizationId}-${item.Organization}`}
                        >
                          {item.Organization}
                        </option>
                      ))}
                      <option>Telenor</option>
                    </select>
                  </div>
                )}
              </div>

              <div className={classes.button__layout}>
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
          <footer className={classes.actions}>
            <button onClick={cancelHandler}>cancel</button>
          </footer>
        </div>
      </Card>
    );
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();

    if (userType == "external") {
      const organization = allOrganizations.find((obj: any) => {
        return obj.Organization === organizationInput.current.value;
      });
      saveExternalUser(organization);
    } else {
      saveInternalUser();
    }

    console.log(nameInput.current.value);
    console.log(familyNameInput.current.value);
    console.log(emailInput.current.value);
    console.log(userType);

    nameInput.current.value = "";
    familyNameInput.current.value = "";
    emailInput.current.value = "";
  };

  async function saveInternalUser() {
    console.log("Save Internal");
    console.log(emailInput.current.value);
    try {
      const response = (await clientAuth.graphql({
        query: addInternalUser,
        variables: {
          Email: emailInput.current.value.trim(),
          Environment: Environment.PRODUCTION
        },
      })) as { data: AddInternalUserMutation };
      console.log(response);
      setResponseStatus(response.data.addInternalUser);
    } catch (e) {
      console.log(e);
    }
  }

  async function saveExternalUser(
    organization_id: getOrganizationsResponse | undefined
  ) {
    console.log("Save External");
    try {
      const response = (await clientAuth.graphql({
        query: addExternalUser,
        variables: {
          Email: emailInput.current.value,
          Name: nameInput.current.value,
          Family_name: familyNameInput.current.value,
          Organization_id: organization_id?.OrganizationId as string,
          Environment: Environment.PRODUCTION,
          Agency: "ANNALECT",
          WelcomeUrl: "https://nordics.annalect.com/",
        },
      })) as { data: AddExternalUserMutation };
      console.log(response);
      setResponseStatus(response.data.addExternalUser);
    } catch (e) {
      console.log(e);
    }
  }

  async function addMemberToGroup() {
    console.log("Add Member Called!");
    try {
      const response = (await clientAuth.graphql({
        query: addUserToGroup,
        variables: {
          Email: responseStatus.Attributes.email,
          Name: responseStatus.Attributes.Name,
          FamilyName: responseStatus.Attributes.FamilyName,
          Group: props.selectedGroup,
          Username: responseStatus.Username,
        },
      })) as { data: AddUserToGroupMutation };
      console.log(response);

      if (response.data.addUserToGroup?.StatusCode == "200") {
        props.setNewGroupMember(true);
        props.setAddMemberActive(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    console.log(responseStatus);
    if (responseStatus.hasOwnProperty("StatusCode")) {
      if ((responseStatus.StatusCode = 200)) {
        addMemberToGroup();
      }
    }
  }, [responseStatus]);

  return (
    <Fragment>
      {backdrop()},{modalOverlay()}
    </Fragment>
  );
};

export default AddGroupMember;
