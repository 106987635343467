import { Fragment, useEffect, useState } from "react";
import classes from "./EditUser.module.css";
import {
  UpdateExternalUserMutation,
  updateExternalUserResponse,
} from "../../API";
import { updateExternalUser } from "../../graphql/mutations";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const EditUser = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPass, setResetPass] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<string | undefined | null>(
    "" as string
  );

  const cancel_handler = () => {
    props.setEditUserActive(false);
  };

  const resetPassHandler = () => {
    if (resetPass) {
      setResetPass(false);
    } else {
      setResetPass(true);
    }
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    updateExternalUserFunction();
  };

  useEffect(() => {
    if (
      props.updateResponse?.StatusCode &&
      props.updateResponse.StatusCode >= 200 &&
      props.updateResponse.StatusCode < 300
    ) {
      props.setEditUserActive(false);
    } else {
      setUpdateMessage(props.updateResponse?.Status);
    }
  }, [props.updateResponse]);

  async function updateExternalUserFunction() {
    setLoading(true);
    try {
      const response = (await clientAuth.graphql({
        query: updateExternalUser,
        variables: {
          Username: props.username,
          ResendInvitation: resetPass,
        },
      })) as { data: UpdateExternalUserMutation };
      console.log(response);
      props.setUpdateResponse(response.data.updateExternalUser);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <form onSubmit={submitHandler}>
        <label>Resend Invitation Email</label>
        <input onChange={resetPassHandler} type="checkbox"></input>

        <br></br>
        {updateMessage !== "" && (
          <p style={{ color: "red" }}>{updateMessage}</p>
        )}
        {loading && <p>processing...</p>}
        <br></br>
        <u onClick={cancel_handler} className={classes.cancel__button}>
          Cancel
        </u>
        <button type="submit">Send</button>
      </form>
    </Fragment>
  );
};

export default EditUser;
