
import { Fragment, useEffect, useState } from "react";
import { signInWithRedirect, forgetDevice, signOut, getCurrentUser } from 'aws-amplify/auth';

const Login = () => {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    getUser()
  },[])

  const getUser = async() => {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      setLoggedIn(true);
    }
  }

  const login = () => {
    signInWithRedirect();

  };

  async function logout() {
    await signOut({ global: true });
    await forgetDevice();
  };

  const logUser = async () => {
    /* console.log(loggedIn)
    logLogin() */
  };


  return (
    <Fragment>
      {!loggedIn && <button onClick={login}>Login</button>}
      {loggedIn && <button onClick={logout}>Log out</button>}
      <button onClick={logUser}>log user</button>
    </Fragment>
  );
};

export default Login;
