import { Fragment } from "react";
import classes from "./DashboardSearch.module.css";
import { getAllDashboardsV2Response } from "../../API";

const DashboardSearch = (props: any) => {
  const searchOnChangeHandler = () => {
    let items = props.allDashboards_v2.filter(
      (item: getAllDashboardsV2Response) =>
        item.Service?.toLowerCase().includes(
          props.searchInput.current.value.toLowerCase()
        )
    );
    props.setFilteredDashboards(items);
  };

  return (
    <Fragment>
      <input
        className={classes.search__input}
        onChange={searchOnChangeHandler}
        placeholder="search"
        ref={props.searchInput}
        type="text"
      />
    </Fragment>
  );
};
export default DashboardSearch;
