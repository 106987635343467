import { Fragment, useEffect, useRef, useState } from "react";
import {
  AccessType,
  AddNewOrganizationMutation,
  AddPortalGroupStatementMutation,
  Environment,
  StatementType,
} from "../../API";
import {
  addNewOrganization,
  addPortalGroupStatement,
} from "../../graphql/mutations";
import InitialAccess from "./InitialAccess";
import classes from "./NewOrganization.module.css";

import { generateClient } from "aws-amplify/api";
const clientAuth = generateClient({authMode: "userPool"});

const NewOrganization = (props: any) => {
  const clientCountryInput: any = useRef();
  const clientCodeInput: any = useRef();
  const organizationInput: any = useRef();

  const [groups, setGroups] = useState<[]>([] as any);
  const [accessTypes, setAccessTypes] = useState<[]>([] as any);
  const [environments, setEnvironments] = useState<[]>([] as any);

  const [invalidInputs, setInvalidInputs] = useState({
    clientCodeInputError: false,
    clientCountryInputError: false,
    clientOrganizationInputError: false,
    groupsInputError: false,
    accessTypesInputError: false,
    environmentsInputError: false,
  } as any);

  const [submitInvalid, setSubmitInvalid] = useState(false);

  const [organizationResponse, setOrganizationResponse] = useState({
    status: 0,
    message: "",
    organization: "",
  });

  const onSubmitHandler = (event: any) => {
    event.preventDefault();

    if (
      clientCountryInput.current.value !== "" &&
      clientCodeInput.current.value !== "" &&
      organizationInput.current.value !== "" &&
      groups.length !== 0 &&
      accessTypes.length !== 0 &&
      environments.length !== 0
    ) {
      createNewOrganization(organizationInput.current.value);
      groups.forEach((group) => {
        accessTypes.forEach((act) => {
          environments.forEach((env) => {
            grantInitialGroupAccess(group, act, env);
          });
        });
      });

      clientCountryInput.current.value = "";
      clientCodeInput.current.value = "";
      organizationInput.current.value = "";
      setGroups([]);
      setAccessTypes([]);
      setEnvironments([]);
    } else {
      setSubmitInvalid(true);

      if (groups.length === 0) {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, groupsInputError: true };
        });
      }
      if (accessTypes.length === 0) {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, accessTypesInputError: true };
        });
      }
      if (clientCountryInput.current.value === "") {
        setInvalidInputs((prevState: any) => {
          return { ...prevState, clientCountryInputError: true };
        });
      }
    }
  };

  async function grantInitialGroupAccess(
    group: string,
    act: string,
    env: string
  ) {
    console.log("calling grantInitialGroupAccess");
    console.log(groups);
    console.log(clientCodeInput.current.value);
    console.log(clientCountryInput.current.value);

    try {
      const response = (await clientAuth.graphql({
        query: addPortalGroupStatement,
        variables: {
          ClientCode: clientCodeInput.current.value,
          ClientCountry: clientCountryInput.current.value,
          Group: group,
          StatementType: StatementType[act as keyof typeof StatementType],
          Environment: Environment[env.toUpperCase() as keyof typeof Environment],
          AccessType: AccessType.FULL,
        },
      })) as { data: AddPortalGroupStatementMutation };
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  async function createNewOrganization(organization: string) {
    console.log("Calling addNewOrganization");
    try {
      const response = (await clientAuth.graphql({
        query: addNewOrganization,
        variables: {
          ClientCode: clientCodeInput.current.value,
          ClientCountry: clientCountryInput.current.value,
          ClientOrganization: organization,
        },
      })) as { data: AddNewOrganizationMutation };
      console.log(response);

      if (
        response.data.addNewOrganization?.hasOwnProperty("PK") &&
        response.data.addNewOrganization?.hasOwnProperty("SK")
      ) {
        setOrganizationResponse((prevState: any) => {
          return {
            ...prevState,
            status: 200,
            organization: organization,
          };
        });
      } else {
        setOrganizationResponse((prevState: any) => {
          return {
            ...prevState,
            status: 500,
            organization: organization,
          };
        });
      }
    } catch (e) {
      console.log(e);
      setOrganizationResponse((prevState: any) => {
        return {
          ...prevState,
          status: 500,
          organization: organization,
        };
      });
    }
  }

  return (
    <Fragment>
      <div>
        {submitInvalid && (
          <p style={{ color: "red" }}>Some fields are invalid</p>
        )}
        <form onSubmit={onSubmitHandler}>
          <input ref={clientCodeInput} placeholder="ClientCode" />
          <select
            className={
              invalidInputs.clientCountryInputError
                ? classes.countryDropDownError
                : ""
            }
            ref={clientCountryInput as any}
          >
            <option value="" disabled selected hidden>
              Country
            </option>
            <option>DK</option>
            <option>SE</option>
            <option>NO</option>
            <option>FI</option>
          </select>
          <input ref={organizationInput} placeholder="Organization name" />
          <br></br>
          <p>
            <b>Select initial access to this client</b>
          </p>

          <InitialAccess
            groups={groups}
            setGroups={setGroups}
            accessTypes={accessTypes}
            setAccessTypes={setAccessTypes}
            environments={environments}
            setEnvironments={setEnvironments}
            invalidInputs={invalidInputs}
          />

          <br></br>
          <br></br>
          <br></br>
          <button
            type="submit"
            style={{
              width: "120px",
              height: "40px",
              backgroundColor: "blue",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Add Organization
          </button>
        </form>
        {organizationResponse.status == 200 && (
          <p style={{ color: "green" }}>
            <b>{`${organizationResponse.organization} - was successfully created`}</b>
          </p>
        )}
        {organizationResponse.status == 500 && (
          <p style={{ color: "red" }}>
            <b>{`Error creating - ${organizationResponse.organization}`}</b>
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default NewOrganization;
