import { Fragment, useContext, useEffect, useRef, useState } from "react";
import classes from "./Dashboards.module.css";
import ResourceContext from "../../Context/resource-context";
import {
  getAllDashboardsResponse,
  getAllDashboardsV2Response,
  StatementType,
} from "../../API";
import Dashboard from "./Dashboard";
import AccessContext from "../../Context/access-context";
import DashboardList from "./DashboardList";
import DashboardSearch from "./DashboardSearch";

const Dashboards = (props: any) => {
  const {
    allDashboards,
    allDashboards_v2,
    selectedDashboard,
    selectedDashboard_v2,
    setSelectedDashboard,
    setSelectedDashboard_v2,
  } = useContext(ResourceContext);
  const { setUserAccessRequest } = useContext(AccessContext);

  const searchInput: any = useRef();

  const [activeEnvironment, setActiveEnvironment] = useState("PRODUCTION");
  const [activeAccessType, setActiveAccessType] = useState<{
    has: string;
    give: string;
  }>({ has: StatementType.Access, give: StatementType.Paddington } as {
    has: StatementType;
    give: StatementType;
  });

  const [filteredDashboards, setFilteredDashboards] = useState<any>(
    [] as getAllDashboardsResponse[]
  );

  const selectedDashboardHandler = (dashboard: getAllDashboardsV2Response) => {
    setSelectedDashboard_v2(dashboard);
    setUserAccessRequest({
      Service: "Dashboards",
      Resource: dashboard.DashboardId,
      User: props.username,
      Environment: activeEnvironment,
      StatementType: activeAccessType,
    });
  };

  useEffect(() => {
    setFilteredDashboards(allDashboards_v2);
  }, [allDashboards_v2]);

  return (
    <Fragment>
      {!selectedDashboard_v2.PK && (
        <div>
          <DashboardSearch
            allDashboards_v2={allDashboards_v2}
            searchInput={searchInput}
            setFilteredDashboards={setFilteredDashboards}
          />
          <br></br>
          <DashboardList
            allDashboards_v2={filteredDashboards}
            selectedDashboardHandler={selectedDashboardHandler}
          ></DashboardList>
        </div>
      )}
      {selectedDashboard_v2.PK && (
        <Dashboard
          selectedDashboard_v2={selectedDashboard_v2}
          activeEnvironment={activeEnvironment}
          setActiveEnvironment={setActiveEnvironment}
          setUserAccessRequest={setUserAccessRequest}
          activeAccessType={activeAccessType}
          setActiveAccessType={setActiveAccessType}
          username={props.username}
        />
      )}
    </Fragment>
  );
};

export default Dashboards;
