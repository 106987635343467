import { Fragment, useContext, useEffect, useState } from "react";
import classes from "./DashboardUsers.module.css";
import Dropdown from "../Dropdown/Dropdown";
import {
  Clients,
  Environment,
  StatementResponse,
  StatementType,
  UserResponse,
  UserType,
  dashboardAccessResponse,
  getDashboardClientsResponse,
} from "../../API";
import EnvironmentContext from "../../Context/environment-context";
import UserContext from "../../Context/user-context";
import ClientContext from "../../Context/client-context";
import AccessContext from "../../Context/access-context";

const DashboardUsers = (props: any) => {
  const { environments, activeEnvironment, setActiveEnvironment } =
    useContext(EnvironmentContext);
  const { setUserType, userType } = useContext(UserContext);
  const { setActiveAccessType, clientList, setClientList } =
    useContext(ClientContext);
  const { dashboardAccess } = useContext(AccessContext);
  const [lookupList, setLookupList] = useState<any>([] as string[]);

  const environmentChange = (args: string) => {
    setActiveEnvironment(Environment[args.toUpperCase() as keyof typeof Environment]);
  };

  const [userTypeActive, setUserTypeActive] = useState<string>("");
  const [filteredAccess, setFilteredAccess] = useState<any>(
    [] as dashboardAccessResponse[]
  );

  const usersRequestHandler = (userType: UserType) => {
    setUserType(userType);
    setUserTypeActive(userType);
  };

  useEffect(() => {
    setActiveEnvironment(Environment.PRODUCTION);
    setUserType(UserType.INTERNAL);
    setUserTypeActive(UserType.INTERNAL);
    setActiveAccessType({
      has: StatementType.Paddington,
      give: StatementType.Access,
    });
  }, []);

  const clientHandler = (client: getDashboardClientsResponse) => {
    setClientList([
      {
        ClientCode: client.Client,
        ClientCountry: client.Country,
      },
    ]);
  };

  useEffect(() => {
    let clientLookup = [] as string[];
    clientList.forEach((client: Clients) => {
      clientLookup.push(`${client.ClientCode}${client.ClientCountry}`);
    });
    setLookupList(clientLookup);
  }, [clientList]);

  useEffect(() => {
    if (userType === UserType.INTERNAL) {
      let items = dashboardAccess.filter((item: dashboardAccessResponse) =>
        item.Username?.toLowerCase().includes("onewp_")
      );
      setFilteredAccess(items);
    } else {
      let items = dashboardAccess.filter(
        (item: dashboardAccessResponse) =>
          !item.Username?.toLowerCase().includes("onewp_")
      );
      setFilteredAccess(items);
    }
  }, [dashboardAccess, userType]);

  const backHandler = () => {
    props.setDashboardUsersActive(false);
  };

  return (
    <Fragment>
      <div className={classes.main__content}>
        <br></br>
        <button
          className={classes.back__button}
          onClick={backHandler}
        >{`← Back`}</button>
        <h2>{props.activeDashboard}</h2>
        <div className={classes.client__list}>
          {props.dashboardClients.map((client: getDashboardClientsResponse) => (
            <div
              key={`${client.Client}${client.Country}`}
              className={
                lookupList.includes(`${client.Client}${client.Country}`)
                  ? `${classes.client__item} ${classes.client__item__marked}`
                  : classes.client__item
              }
            >
              <p
                onClick={() => {
                  clientHandler(client);
                }}
              >
                {`${client.Client} - ${client.Country}`}
              </p>
            </div>
          ))}
        </div>
        <br></br>
        <Dropdown
          data={environments}
          type={"Environment"}
          value={activeEnvironment}
          label={"Select Environment"}
          onChange={environmentChange}
        />
        <div>
          <br></br>
          <button
            className={
              userTypeActive == UserType.INTERNAL
                ? `${classes.active__userType} ${classes.none__active__userType}`
                : `${classes.none__active__userType}`
            }
            onClick={() => usersRequestHandler(UserType.INTERNAL)}
          >
            Internal
          </button>
          <button
            className={
              userTypeActive == UserType.EXTERNAL
                ? `${classes.active__userType} ${classes.none__active__userType}`
                : `${classes.none__active__userType}`
            }
            onClick={() => usersRequestHandler(UserType.EXTERNAL)}
          >
            External
          </button>
        </div>
        <h3>Users</h3>

        {filteredAccess.map((user: dashboardAccessResponse) => (
          <div key={`${user.Username}`} className={classes.user__item}>
            <p>
              <b>{`${user.Name} ${user.FamilyName}`}</b>
            </p>
            <p>{user.Email}</p>
            <div className={classes.test}></div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default DashboardUsers;
