import { Fragment, useContext, useState } from "react";
import UserContext from "../../Context/user-context";
import classes from "./GroupList.module.css";
import GroupMembers from "./GroupMembers";
import Dashboards from "../Dashboards/Dashboards";
import ResourceContext from "../../Context/resource-context";
import {
  getAllDashboardsResponse,
  getAllDashboardsV2Response,
} from "../../API";
import AccessContext from "../../Context/access-context";
import CM from "../CampaignMapping/CM";
import OMGPortal from "../Portal/OMG/OMGPortal";

const GroupList = (props: any) => {
  const { groups, setSelectedGroup } = useContext(UserContext);
  const {
    activeService,
    setActiveService,
    setSelectedDashboard,
    setSelectedDashboard_v2,
  } = useContext(ResourceContext);
  const { groupAccessActive, setGroupAccessActive } = useContext(AccessContext);

  const [groupMembersActive, setGroupMembersActive] = useState<boolean>(false);

  const setGroupHandler = (group: string) => {
    setSelectedGroup(group);
    setGroupMembersActive(true);
  };

  const groupAccessHandler = (group: string) => {
    setGroupAccessActive({ Active: true, Group: group });
  };

  const serviceHandler = (service: string) => {
    setActiveService(service);

    if (service === "dashboard") {
      setSelectedDashboard_v2({} as getAllDashboardsV2Response);
    }
  };

  const backHandler = () => {
    setGroupAccessActive({ Active: false, Group: "" });
  };

  return (
    <Fragment>
      {groupMembersActive && (
        <GroupMembers setGroupMembersActive={setGroupMembersActive} />
      )}
      {!groupMembersActive && !groupAccessActive.Active && (
        <div>
          {groups.map((group: string) => (
            <div key={group} className={classes.group__item}>
              <p>
                <b>{group}</b>
              </p>
              <button onClick={() => setGroupHandler(group)}>
                View members
              </button>
              <button onClick={() => groupAccessHandler(group)}>
                View Access
              </button>
            </div>
          ))}
        </div>
      )}
      {groupAccessActive.Active && (
        <div className={classes.page__layout}>
          <h2>{groupAccessActive.Group}</h2>
          <div>
            <button
              className={classes.back__button}
              onClick={backHandler}
            >{`← Back to groups`}</button>
          </div>
          <div>
            <button
              className={
                activeService === "dashboard"
                  ? `${classes.active__button}`
                  : `{}`
              }
              onClick={() => serviceHandler("dashboard")}
            >
              Dashboards
            </button>
            <button
              className={
                activeService === "cm" ? `${classes.active__button}` : `{}`
              }
              onClick={() => serviceHandler("cm")}
            >
              Campaign Mapping
            </button>
            <button
              className={
                activeService === "OMG Portal" ? `${classes.active__button}` : `{}`
              }
              onClick={() => serviceHandler("OMG Portal")}
            >
              OMG Portal
            </button>
          </div>
          {activeService === "dashboard" && (
            <div>
              <Dashboards username={groupAccessActive.Group} />
            </div>
          )}
          {activeService === "cm" && <CM username={groupAccessActive.Group} />}
          {activeService === "OMG Portal" && <OMGPortal username={groupAccessActive.Group} />}
        </div>
      )}
    </Fragment>
  );
};

export default GroupList;
