import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Environment,
  GetAllDashboards_v2Query,
  GetAllDashboardsQuery,
  getAllDashboardsResponse,
  getAllDashboardsV2Response,
  GetDashboardClientsQuery,
  getDashboardClientsResponse,
  GetDashboardsQuery,
  getDashboardsResponse,
} from "../API";
import {
  getAllDashboards,
  getAllDashboards_v2,
  getDashboardClients,
  getDashboards,
} from "../graphql/queries";
import OrganizationContext from "./organization-context";
import EnvironmentContext from "./environment-context";
import { generateClient } from "aws-amplify/api";
const client = generateClient({authMode: "userPool"});

const ResourceContext = createContext({
  dashboards: [] as getDashboardsResponse[],
  activeService: "" as string,
  allDashboards: [] as getAllDashboardsResponse[],
  allDashboards_v2: [] as getAllDashboardsV2Response[],
  selectedDashboard: {} as getAllDashboardsResponse,
  selectedDashboard_v2: {} as getAllDashboardsV2Response,
  dashboardClients: [] as getDashboardClientsResponse[],
  setDashboards: (args: getDashboardsResponse[]) => {},
  setActiveService: (args: string) => {},
  setSelectedDashboard: (args: getAllDashboardsResponse) => {},
  setSelectedDashboard_v2: (args: getAllDashboardsV2Response) => {},
  setAllDashboards: (args: getAllDashboardsResponse[]) => {},
  setAllDashboards_v2: (args: getAllDashboardsV2Response[]) => {},
});

export const ResourceContextProvider = (props: any) => {
  const [dashboards, setDashboards] = useState<any>(
    [] as getDashboardsResponse[]
  );

  const [allDashboards, setAllDashboards] = useState<any>(
    [] as getAllDashboardsResponse[]
  );

  const [allDashboards_v2, setAllDashboards_v2] = useState<any>(
    [] as getAllDashboardsV2Response[]
  );

  const [dashboardClients, setDashboardClients] = useState<any>(
    [] as getDashboardClientsResponse[]
  );

  const [activeService, setActiveService] = useState<string>("");
  const [selectedDashboard, setSelectedDashboard] =
    useState<getAllDashboardsResponse>({} as getAllDashboardsResponse);

  const [selectedDashboard_v2, setSelectedDashboard_v2] =
    useState<getAllDashboardsV2Response>({} as getAllDashboardsV2Response);

  const { activeOrganization } = useContext(OrganizationContext);
  const { activeEnvironment } = useContext(EnvironmentContext);

  const fetchDashboards = useCallback(async () => {
    console.log("Calling fetch Dashboards");
    try {
      const responseList = (await client.graphql({
        query: getDashboards,
        variables: {
          Organization_id: activeOrganization.OrganizationId as string,
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
        }
      })) as { data: GetDashboardsQuery };
      console.log(responseList);
      setDashboards(responseList.data.getDashboards);
    } catch (e) {
      console.log(e);
    }
  }, [activeEnvironment, activeOrganization]);

  useEffect(() => {
    if (activeEnvironment && Object.keys(activeOrganization).length > 0) {
      fetchDashboards();
    }
  }, [fetchDashboards, activeEnvironment, activeOrganization]);

  const fetchAllDashboards = useCallback(async () => {
    console.log("Calling fetch all dashboards");
    try {
      const response = (await client.graphql({
        query: getAllDashboards,
      })) as { data: GetAllDashboardsQuery };
      console.log(response);
      setAllDashboards(response.data.getAllDashboards);
    } catch (e) {
      console.log(e);
    }
  }, [activeService]);

  const fetchAllDashboards_v2 = useCallback(async () => {
    console.log("Calling fetch all dashboards_v2");
    try {
      const response = (await client.graphql({
        query: getAllDashboards_v2,
      })) as { data: GetAllDashboards_v2Query };
      console.log(response);
      setAllDashboards_v2(response.data.getAllDashboards_v2);
    } catch (e) {
      console.log(e);
    }
  }, [activeService]);

  useEffect(() => {
    if (activeService === "dashboard") {
      fetchAllDashboards();
      fetchAllDashboards_v2();
    }
  }, [fetchAllDashboards, activeService]);

  const fetchDashboardClients = useCallback(async () => {
    console.log("calling fetch selected dashboard");
    try {
      const response = (await client.graphql({
        query: getDashboardClients,
        variables: {
          Resource: selectedDashboard_v2.Resource as string,
          Service: selectedDashboard_v2.Service as string,
        }
      })) as { data: GetDashboardClientsQuery };
      console.log(response);
      setDashboardClients(response.data.getDashboardClients);
    } catch (e) {
      console.log(e);
    }
  }, [selectedDashboard_v2]);

  useEffect(() => {
    if (selectedDashboard_v2.PK) {
      fetchDashboardClients();
    }
  }, [fetchDashboardClients, selectedDashboard_v2]);

  return (
    <ResourceContext.Provider
      value={{
        dashboards,
        activeService,
        selectedDashboard,
        allDashboards,
        allDashboards_v2,
        selectedDashboard_v2,
        dashboardClients,
        setDashboards,
        setActiveService,
        setSelectedDashboard,
        setAllDashboards,
        setAllDashboards_v2,
        setSelectedDashboard_v2,
      }}
    >
      {props.children}
    </ResourceContext.Provider>
  );
};

export default ResourceContext;
