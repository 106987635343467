import { Fragment, useContext, useState, useRef, useEffect } from "react";
import UserContext from "../../Context/user-context";
import OrganizationContext from "../../Context/organization-context";
import classes from "./InitialAccess.module.css";

const InitialAccess = (props: any) => {
  const { groups } = useContext(UserContext);
  const { setNewOrganizationActive } = useContext(OrganizationContext);

  const accessTypes = ["Access", "Paddington"];
  const environments = ["Production", "Staging", "Demo"];

  useEffect(() => {
    setNewOrganizationActive(true);
    console.log(props);
  }, []);

  const onGroupSelectionHandler = (group: string) => {
    if (props.groups.includes(group)) {
      const arr = props.groups.filter((item: string) => item !== group);
      props.setGroups(arr);
    } else {
      props.setGroups((prevState: string[]) => {
        return [...prevState, group];
      });
    }
  };

  const onAccessTypeSelectionHandler = (at: string) => {
    if (props.accessTypes.includes(at)) {
      const arr = props.accessTypes.filter((item: string) => item !== at);
      props.setAccessTypes(arr);
    } else {
      props.setAccessTypes((prevState: string[]) => {
        return [...prevState, at];
      });
    }
  };

  const onEnvironmentSelectionHandler = (env: string) => {
    if (props.environments.includes(env)) {
      const arr = props.environments.filter((item: string) => item !== env);
      props.setEnvironments(arr);
    } else {
      props.setEnvironments((prevState: string[]) => {
        return [...prevState, env];
      });
    }
  };

  return (
    <Fragment>
      <div className={classes.initialAccessBlock}>
        <div
          className={
            props.invalidInputs.groupsInputError
              ? classes.groupsElementError
              : classes.groupsElement
          }
        >
          <div>
            <p>
              <b>Groups</b>
            </p>
            {groups.map((group: any) => (
              <p
                className={
                  props.groups.includes(group)
                    ? classes.optionElementSelected
                    : classes.optionElement
                }
                key={group}
                onClick={() => {
                  onGroupSelectionHandler(group);
                }}
              >
                {group}
              </p>
            ))}
          </div>
        </div>

        <div
          className={
            props.invalidInputs.accessTypesInputError
              ? classes.accessTypesError
              : classes.accessTypes
          }
        >
          <div>
            <p>
              <b>AccessTypes</b>
            </p>
            {accessTypes.map((at: string) => (
              <p
                onClick={() => {
                  onAccessTypeSelectionHandler(at);
                }}
                key={at}
                className={
                  props.accessTypes.includes(at)
                    ? classes.optionElementSelected
                    : classes.optionElement
                }
              >
                {at}
              </p>
            ))}
          </div>

          <div style={{ width: "90%" }}>
            <p>
              <b>Environments</b>
            </p>
            {environments.map((env: string) => (
              <p
                onClick={() => {
                  onEnvironmentSelectionHandler(env);
                }}
                key={env}
                className={
                  props.environments.includes(env)
                    ? classes.optionElementSelected
                    : classes.optionElement
                }
              >
                {env}
              </p>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InitialAccess;
