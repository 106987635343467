//import { API } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AccessLevel,
  ClientList,
  Country,
  Environment,
  GetDashboardAccessQuery,
  GetProjectAccessQuery,
  GetStatementsFromClientQuery,
  GetStatementsQuery,
  StatementType,
  dashboardAccessResponse,
  projectAccessResponse,
} from "../API";
import {
  UserClientAccessRequest,
  UserClientAccessType as UserClientAccessType,
} from "../Custom-Types";
import {
  getDashboardAccess,
  getProjectAccess,
  getStatements,
  getStatementsFromClient,
} from "../graphql/queries";
import EnvironmentContext from "./environment-context";
import UserContext from "./user-context";
import ClientContext from "./client-context";

const AccessContext = createContext({
  currentUserAccess: {} as ClientList,
  userAccess: {} as ClientList,
  groupAccessActive: {} as { Active: boolean; Group: string },
  currentUserCampaignMappingAccess: [] as UserClientAccessType[],
  userCampaignMappingAccess: [] as UserClientAccessType[],
  dashboardAccess: [] as dashboardAccessResponse[],
  projectAccess: [] as projectAccessResponse[],
  setUserAccessRequest: (args: {
    Service: string | null | undefined;
    Resource: string | null | undefined;
    User: string | null | undefined;
    Environment: string | null | undefined;
    StatementType: {
      has: string;
      give: string;
    };
  }) => {},
  setGroupAccessActive: (args: { Active: boolean; Group: string }) => {},
  setCampaignMappingAccessRequest: (args: UserClientAccessRequest) => {},
  setUserCampaignMappingAccess: (args: UserClientAccessType[]) => {}
});

const client = generateClient({authMode: "userPool"});

export const AccessContextProvider = (props: any) => {
  const { activeUser, activeDashboard, selectedUser, userType } =
    useContext(UserContext);
  const { activeEnvironment } = useContext(EnvironmentContext);
  const {
    activeAccessType,
    activeAgency,
    activeCountry,
    activeEnvironment: cmActiveEnvironment,
  } = useContext(ClientContext);

  const { clientList } = useContext(ClientContext);

  const [userAccess, setUserAccess] = useState<any>({} as ClientList);
  const [currentUserAccess, setCurrentUserAccess] = useState<any>(
    {} as ClientList
  );

  const [groupAccessActive, setGroupAccessActive] = useState<{
    Active: boolean;
    Group: string;
  }>({ Active: false, Group: "" } as { Active: boolean; Group: string });

  const [userAccessRequest, setUserAccessRequest] = useState<{
    Service: string | null | undefined;
    Resource: string | null | undefined;
    User: string | null | undefined;
    Environment: string | null | undefined;
    StatementType: {
      has: string;
      give: string;
    };
  }>(
    {} as {
      Service: string;
      Resource: string;
      User: string;
      Environment: string;
      StatementType: {
        has: string;
        give: string;
      };
    }
  );

  const [projectAccess, setProjectAccess] = useState<projectAccessResponse[]>([] as projectAccessResponse[])

  const [campaignMappingAccessRequest, setCampaignMappingAccessRequest] =
    useState<UserClientAccessRequest>({} as UserClientAccessRequest);
  const [
    currentUserCampaignMappingAccess,
    setCurrentUserCampaignMappingAccess,
  ] = useState<any>([] as UserClientAccessType[]);

  const [userCampaignMappingAccess, setUserCampaignMappingAccess] =
    useState<any>([] as UserClientAccessType[]);

  const [dashboardAccess, setDashboardAccess] = useState<any>(
    [] as dashboardAccessResponse[]
  );

  const fetchUserAccess = useCallback(async () => {
    console.log("Calling fetchUserAccess");
    try {
      const response = (await client.graphql({
        query: getStatements,
        variables: {
          Environment: Environment[userAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
          Service: userAccessRequest.Service || '',
          Resource: userAccessRequest.Resource || '',
          StatementType: StatementType[userAccessRequest.StatementType.has as keyof typeof StatementType],
          User: userAccessRequest.User,
        }
      })) as { data: GetStatementsQuery };
      console.log(response);
      setUserAccess(response.data.getStatements);
    } catch (e) {
      console.log(e);
    }
  }, [activeEnvironment, userAccessRequest]);

  const fetchCurrentUserAccess = useCallback(async () => {
    console.log("Calling fetch current user access");
    try {
      const response = (await client.graphql({
        query: getStatements,
        variables: {
          Environment: Environment[userAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
          Service: userAccessRequest.Service || '',
          Resource: userAccessRequest.Resource || '',
          StatementType: StatementType[userAccessRequest.StatementType.give as keyof typeof StatementType],
        }
      })) as { data: GetStatementsQuery };
      console.log(response);
      setCurrentUserAccess(response.data.getStatements);
    } catch (e) {
      console.log();
    }
  }, [activeEnvironment, userAccessRequest]);

  useEffect(() => {
    console.log(userAccessRequest);
    if (userAccessRequest.Environment) {
      fetchCurrentUserAccess();
      if (userAccessRequest.User) {
        fetchUserAccess();
      }
    }
  }, [
    fetchUserAccess,
    fetchCurrentUserAccess,
    activeEnvironment,
    userAccessRequest,
  ]);

  const fetchCurrentUserCampaignMappingAccess = useCallback(
    async (reset: boolean) => {
      console.log("Calling fetch current campaign mapping access");
      try {
        const response = (await client.graphql({
          query: getStatementsFromClient,
          variables: {
            ClientCode: campaignMappingAccessRequest.ClientCode || '',
            ClientCountry: Country[campaignMappingAccessRequest.ClientCountry as keyof typeof Country],
            Environment: Environment[campaignMappingAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
            Service: campaignMappingAccessRequest.Service || '',
            StatementType: StatementType[campaignMappingAccessRequest.StatementType.give as keyof typeof StatementType],
          }
        })) as { data: GetStatementsFromClientQuery };
        console.log(response);
        if (reset) {
          setUserCampaignMappingAccess([
            {
              Client: campaignMappingAccessRequest.ClientCode,
              Access: response.data.getStatementsFromClient,
            },
          ]);
        } else {
          setCurrentUserCampaignMappingAccess((prevState: any) => {
            return [
              ...prevState,
              {
                Client: campaignMappingAccessRequest.ClientCode,
                Access: response.data.getStatementsFromClient,
              },
            ];
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [campaignMappingAccessRequest]
  );

  useEffect(() => {
    if (campaignMappingAccessRequest.ClientCode) {
      const arr = currentUserCampaignMappingAccess.filter(
        (item: UserClientAccessType) =>
          item.Client === campaignMappingAccessRequest.ClientCode
      );
      if (arr[0]?.Client !== campaignMappingAccessRequest.ClientCode) {
        console.log(arr[0]?.Client, campaignMappingAccessRequest.ClientCode);
        fetchCurrentUserCampaignMappingAccess(false);
      }
    }
  }, [campaignMappingAccessRequest, fetchCurrentUserCampaignMappingAccess]);

  const fetchUserCampaignMappingAccess = useCallback(
    async (reset: boolean) => {
      console.log("calling fetch campaign mapping access");
      try {
        const response = (await client.graphql({
          query: getStatementsFromClient,
          variables: {
            ClientCode: campaignMappingAccessRequest.ClientCode || '',
            ClientCountry: Country[campaignMappingAccessRequest.ClientCountry as keyof typeof Country],
            Environment: Environment[campaignMappingAccessRequest.Environment?.toUpperCase() as keyof typeof Environment],
            Service: campaignMappingAccessRequest.Service || '',
            StatementType: StatementType[campaignMappingAccessRequest.StatementType.has as keyof typeof StatementType],
            User: campaignMappingAccessRequest.User,
          }
        })) as { data: GetStatementsFromClientQuery };
        console.log(response);
        if (reset) {
          setUserCampaignMappingAccess([
            {
              Client: campaignMappingAccessRequest.ClientCode,
              Access: response.data.getStatementsFromClient,
            },
          ]);
        } else {
          setUserCampaignMappingAccess((prevState: any) => {
            return [
              ...prevState,
              {
                Client: campaignMappingAccessRequest.ClientCode,
                Access: response.data.getStatementsFromClient,
              },
            ];
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [campaignMappingAccessRequest, activeUser]
  );

  useEffect(() => {
    if (campaignMappingAccessRequest.ClientCode) {
      const arr = userCampaignMappingAccess.filter(
        (item: UserClientAccessType) =>
          item.Client === campaignMappingAccessRequest.ClientCode
      );
      if (arr[0]?.Client !== campaignMappingAccessRequest.ClientCode) {
        fetchUserCampaignMappingAccess(false);
      }
    }
  }, [campaignMappingAccessRequest, activeUser]);

  useEffect(() => {
    if (campaignMappingAccessRequest.ClientCode) {
      setUserCampaignMappingAccess([] as UserClientAccessType[]);
      setCurrentUserCampaignMappingAccess(
        [] as UserClientAccessType[]
      );
    }
  }, [
    activeAccessType,
    selectedUser,
    activeAgency,
    activeCountry,
    userType,
    groupAccessActive,
    cmActiveEnvironment,
  ]);

  const fetchDashboardAccess = useCallback(async () => {
    console.log("Calling fetchDashboardAccess!");
    try {
      const responseList = (await client.graphql({
        query: getDashboardAccess,
        variables: {
          Clients: clientList,
          Environment: Environment[activeEnvironment.toUpperCase() as keyof typeof Environment],
          Resource: activeDashboard || '',
          StatementType: activeAccessType.give,
          UserType: userType,
        }
      })) as { data: GetDashboardAccessQuery };
      console.log(responseList);
      setDashboardAccess(responseList.data.getDashboardAccess);
    } catch (e) {
      console.log(e);
    }
  }, [
    activeEnvironment,
    activeAccessType,
    clientList,
    activeDashboard,
    userType,
  ]);

  useEffect(() => {
    if (
      activeDashboard &&
      activeAccessType &&
      activeEnvironment &&
      clientList.length > 0
    ) {
      fetchDashboardAccess();
    }
  }, [
    activeEnvironment,
    activeAccessType,
    clientList,
    activeDashboard,
    fetchDashboardAccess,
  ]);

  const fetchProjectAccess = useCallback(async () => {
    console.log("Calling fetchProjectAccess!");
    try {
      const response = (await client.graphql({
        query: getProjectAccess,
        variables: {
         Level: AccessLevel.FULL_ACCESS
        }
      })) as { data: GetProjectAccessQuery };
      console.log(response);
      setProjectAccess(response.data.getProjectAccess as projectAccessResponse[]);
    } catch (e) {
      console.log(e);
    }
  },[])

  useEffect(() => {
    fetchProjectAccess()
  },[fetchProjectAccess])



  return (
    <AccessContext.Provider
      value={{
        userAccess,
        currentUserAccess,
        groupAccessActive,
        currentUserCampaignMappingAccess,
        userCampaignMappingAccess,
        dashboardAccess,
        projectAccess,
        setUserAccessRequest,
        setGroupAccessActive,
        setCampaignMappingAccessRequest,
        setUserCampaignMappingAccess,
      }}
    >
      {props.children}
    </AccessContext.Provider>
  );
};

export default AccessContext;
