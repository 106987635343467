import { Fragment, useContext } from "react";
import classes from "./TopHeader.module.css";
import UserContext from "../../Context/user-context";
import {
  ClientCodesResponse,
  getAllDashboardsResponse,
  StatementType,
  UserResponse,
  UserType,
} from "../../API";
import ClientContext from "../../Context/client-context";
import { SelectedUser, StatementTypeRequest } from "../../Custom-Types";
import ResourceContext from "../../Context/resource-context";

const TopHeader = (props: any) => {
  const { setUserType, setSelectedGroup, setSelectedUser, setGroup } =
    useContext(UserContext);
  const { setActiveAccessType, setActiveAgency, setCampaignMappingClients } =
    useContext(ClientContext);
  const { setActiveService, setSelectedDashboard } =
    useContext(ResourceContext);

  const moduleHandler = (args: string) => {
    setActiveAccessType({
      has: StatementType.Access,
      give: StatementType.Paddington,
    } as StatementTypeRequest);
    setActiveAgency("" as string);
    setSelectedGroup("" as string);
    setSelectedUser({} as SelectedUser);
    setActiveService("" as string);
    setSelectedDashboard({} as getAllDashboardsResponse);
    setCampaignMappingClients([] as ClientCodesResponse[]);
    setGroup({} as UserResponse);

    props.setModule(args);
    if (args == "Groups") {
      setUserType(UserType.GROUP);
    } else {
      setUserType(UserType.NONE);
    }
  };

  return (
    <Fragment>
      <div className={classes.topnav}>
        <p onClick={() => moduleHandler("Dashboards")}>Dashboards</p>
        {/* <p onClick={() => moduleHandler("Clients")}>Clients</p> */}
        <p onClick={() => moduleHandler("Groups")}>Groups</p>
        <p onClick={() => moduleHandler("Users")}>Users</p>
      </div>
    </Fragment>
  );
};

export default TopHeader;
