import { Fragment, useContext, useEffect, useState } from "react";
import { GroupMemeberInfo } from "../../API";
import UserContext from "../../Context/user-context";
import classes from "./GroupMembers.module.css";
import AddGroupMember from "./AddGroupMember";

const GroupMembers = (props: any) => {
  const { groupMembers, selectedGroup, setNewGroupMember } =
    useContext(UserContext);
  const [addMemberActive, setAddMemberActive] = useState<boolean>(false);

  const activeMemberHandler = () => {
    setAddMemberActive(true);
    setNewGroupMember(false);
  };

  const activeHandler = () => {
    props.setGroupMembersActive(false);
  };

  return (
    <Fragment>
      <div className={classes.layout}>
        <button className={classes.back__button} onClick={activeHandler}>
          {`← Back to groups`}
        </button>
        <h2>{selectedGroup}</h2>

        <button
          className={classes.add__member_button}
          onClick={activeMemberHandler}
        >
          + Add Member
        </button>
        {addMemberActive && (
          <AddGroupMember
            setAddMemberActive={setAddMemberActive}
            selectedGroup={selectedGroup}
            setNewGroupMember={setNewGroupMember}
          />
        )}

        <div>
          {groupMembers.map((member: GroupMemeberInfo) => (
            <div>
              {member.Name != null && (
                <div
                  className={classes.group__member__item}
                  key={member.Username}
                >
                  <p>{`${member.Name} ${member.FamilyName}`}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default GroupMembers;
