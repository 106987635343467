import { createContext, useState } from "react";

const EnvironmentContext = createContext({
  environments: [] as string[],
  activeEnvironment: "",
  setActiveEnvironment: (args: string) => {},
});

export const EnvironmentContextProvider = (props: any) => {
  const environments: string[] = ["DEMO", "STAGING", "PRODUCTION"];
  const [activeEnvironment, setActiveEnvironment] = useState("");

  return (
    <EnvironmentContext.Provider
      value={{
        environments,
        activeEnvironment,
        setActiveEnvironment,
      }}
    >
      {props.children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentContext;
