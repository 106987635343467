
import { generateClient } from "aws-amplify/api";
import { createContext, useCallback, useEffect, useState } from "react";
import {
  ClientCodesResponse,
  ClientResponse,
  Clients,
  Country,
  Environment,
  GetCampaignMappingClientsQuery,
  StatementType,
} from "../API";
import { StatementTypeRequest } from "../Custom-Types";
import { getCampaignMappingClients } from "../graphql/queries";

const client = generateClient({authMode: "userPool"});

const ClientContext = createContext({
  campaignMappingClients: [] as ClientCodesResponse[],
  activeCountry: Country.DK as Country,
  activeAgency: "" as string,
  activeAccessType: {} as StatementTypeRequest,
  activeEnvironment: Environment.PRODUCTION as Environment,
  clientList: [] as Clients[],
  setActiveCountry: (args: Country) => {},
  setActiveAgency: (args: string) => {},
  setActiveAccessType: (args: StatementTypeRequest) => {},
  setActiveEnvironment: (args: Environment) => {},
  setCampaignMappingClients: (args: ClientCodesResponse[]) => {},
  setClientList: (args: Clients[]) => {},
});

export const ClientContextProvider = (props: any) => {
  const [campaignMappingClients, setCampaignMappingClients] = useState<any>(
    [] as ClientCodesResponse[]
  );

  const [clientList, setClientList] = useState<any>([] as Clients[]);

  const [activeCountry, setActiveCountry] = useState(Country.DK);
  const [activeAccessType, setActiveAccessType] =
    useState<StatementTypeRequest>({
      has: StatementType.Access,
      give: StatementType.Paddington,
    } as StatementTypeRequest);
  const [activeEnvironment, setActiveEnvironment] = useState(
    Environment.PRODUCTION
  );

  const [activeAgency, setActiveAgency] = useState("" as string);

  const fetchCampaignMappingClients = useCallback(async () => {
    console.log("Calling Campaign Mapping Clients");
    console.log(activeCountry);
    try {
      const response = (await client.graphql({
        query: getCampaignMappingClients,
        variables: {
          country: activeCountry,
          agency: activeAgency,
        },
      })) as { data: GetCampaignMappingClientsQuery };
      console.log(response);
      setCampaignMappingClients(response.data.getCampaignMappingClients);
    } catch (e) {
      console.log(e);
    }
  }, [activeCountry, activeAgency]);

  useEffect(() => {
    if (activeAgency !== "") {
      fetchCampaignMappingClients();
    }
  }, [activeAgency, activeCountry]);

  return (
    <ClientContext.Provider
      value={{
        campaignMappingClients,
        activeCountry,
        activeAgency,
        activeAccessType,
        activeEnvironment,
        clientList,
        setActiveCountry,
        setActiveAgency,
        setActiveAccessType,
        setActiveEnvironment,
        setCampaignMappingClients,
        setClientList,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
